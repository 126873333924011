<template>
  <div class="card">
    <div class="card__top">
      <div class="card-info">
        <div class="card-info__left">
          <div class="card-info-select">
            <PixieEditor @image-uploaded="imageUploaded" :show-button="false" ref="pixie" />
            <div class="card-select-image">
              <div class="card-select-image__single" v-if="(slickImages && slickImages.length === 1 &&
                !$store.state.main.options.allowCustomImage) ||
                !!$store.state.main.options.imageSelect
              ">
                <div class="card-select-image__discount" v-if="$store.state.main.product.properties &&
                  $store.state.main.product.properties.face_multiplier &&
                  $store.state.main.product.properties.face_multiplier < 1
                ">
                  <img src="../assets/svg/discount.svg" alt="">
                  <div class="card-select-image__discount-text">
                    <div>{{ $t('form.discount') }}</div>
                    <div class="card-select-image__discount-text-value">{{ ((1 -
                        $store.state.main.product.properties.face_multiplier) * 100).toFixed()
                    }}%</div>
                  </div>
                </div>
                <div class="card-select-image__image"
                  :style="{ backgroundImage: `url(${$store.getters['card/current'].cardImage})` }"></div>
              </div>

              <div v-else>

                <div class="card-select-image-multiple">

                  <div class="card-info-select__arrows">
                    <button type="button" class="card-info-select__arrow card-info-select__arrow--prev">
                      <IconArrow style="transform: rotate(180deg)" :fill="'#FFFFFF'" />
                    </button>
                    <button type="button" class="card-info-select__arrow card-info-select__arrow--next">
                      <IconArrow :fill="'#FFFFFF'" />
                    </button>
                  </div>

                  <div class="card-select-image-multiple__container">
                    <div class="card-select-image__discount" v-if="$store.state.main.product.properties &&
                      $store.state.main.product.properties.face_multiplier &&
                      $store.state.main.product.properties.face_multiplier < 1
                    ">
                      <img src="../assets/svg/discount.svg" alt="">
                      <div class="card-select-image__discount-text">
                        <div>{{ $t('form.discount') }}</div>
                        <div class="card-select-image__discount-text-value">{{ ((1 -
                            $store.state.main.product.properties.face_multiplier) * 100).toFixed()
                        }}%</div>
                      </div>
                    </div>
                    <slick ref="slick" :options="slickOptions" @afterChange="handleAfterChange">
                      <div class="card-select-image-multiple__item" v-for="(image, index) in slickImages" :key="index">
                        <div class="card-select-image__image" :style="{ backgroundImage: `url(${image})` }"></div>
                      </div>
                      <div class="card-select-image-multiple__item card-select-image-multiple__item--custom-image"
                        v-if="$store.state.main.options.allowCustomImage">
                        <div class="card-select-image__custom-image">
                          <div class="card-select-image__custom-image-container event__action--custom-image"
                            @click="selectFile">
                            <div class="card-select-image__custom-image-gift">
                              <div class="card-select-image__custom-text">
                                <span v-html="$t('form.choose_gift_card_design')"></span>
                              </div>
                              <div class="card-select-image__image-icon">
                                <IconUploadImage />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </slick>
                  </div>
                </div>
                <div class="card__block-header card__block-header--main"
                  v-if="$store.state.main.options.allowCustomImage">
                  <template>
                    {{ $t('form.choose_the_design_short') }}
                    <span class="no-wrap">
                      {{ $t('form.or') }}
                      <default-btn is-link @click.prevent="clickOnUploadMyImage()">
                        {{ $t('form.create_yours') }}
                      </default-btn>
                    </span>
                  </template>
                </div>
                <div class="card__block-header card__block-header--main"
                  v-html="$t(`form.choose_the_design.${ $store.state.main.product.kind }`)"
                  v-else></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-info__right"
          :class="{ 'card-info__right-padding-bottom': !((slickImages && slickImages.length === 1 && !$store.state.main.options.allowCustomImage) || !!$store.state.main.options.imageSelect) }">

          <div class="card-info__top">
            <Text705 v-if="$store.state.main.product.id === 705" />
            <div v-else>
              <div class="card-info__title"
                :class="{ 'card-info__title--no-logo': !($store.state.main.options.logoImage) }"
                v-if="['AddCard'].includes($route.name)">
                <span>{{ $t(`app.title.${ $store.state.main.product.kind }`) }}</span>
              </div>

              <div class="card-info__name" v-if="$store.state.main.product.title">
                <div v-html="$store.state.main.product.title"></div>
                <div class="card-info__name-sub" v-if="extendedTitle">({{ extendedTitle }})</div>
              </div>
            </div>

          </div>
          <div v-if="hasFaces">
            <div class="card-info__block-container">
              <div class="card-info__block">
                <div class="card-info__block-header" v-if="hasMultipleFaces">
                  {{ $t('form.choose_face') }}
                </div>
                <div class="card-info__block-header" v-else>{{ $t('form.face') }}</div>
                <div class="card-info__block-content">
                  <face-selector @set-description="setDescription"></face-selector>
                </div>
              </div>

              <div class="card-info__block">
                <div class="card-info__block-header">
                  {{ $t('form.enter_quantity') }}
                  <div class="widget-tooltip" v-if="$store.getters['cart/quantity'] >= $store.state.main.maxCartCount">
                    <div class="widget-tooltip__icon">
                      <IconWarning />
                    </div>
                    <div class="widget-tooltip__content" tabindex="-1" v-if="!isPayVisible">
                      <div class="widget-tooltip__content-text">
                        <span v-html="$t('cart.full_cart')"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-info__block-content">
                  <quantity-selector></quantity-selector>
                </div>
              </div>
            </div>
            <div class="card-info__block-notation" v-if="!!commissionValue">
              <span>{{ $t('form.to_pay') }}</span>&nbsp;&mdash;
               <div class="widget-tooltip">
                <div class="widget-tooltip__icon">
                   <a>{{ commissionValue }}</a>
                </div>
                <div class="widget-tooltip__content" tabindex="-1">
                  <div class="widget-tooltip__content-text">
                    <span> {{ $t('form.commission') }} {{ commissionPercent }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-info__block-container" v-else>
            <div class="card-info__block-content">
              <span class="card-info__warning">{{ $t(`form.gift_cards_unavailable.${ $store.state.main.product.kind }`) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card__activation-info">
      <ActivationInfo v-if="parseInt($store.state.main.product.properties.activation_delay) !== 0"
        :delay="parseInt($store.state.main.product.properties.activation_delay) || 0" />
    </div>

    <div class="card__bottom">

      <div class="card__block">
        <recipient ref="recipient" @pay-my-self="addToCartAndPay()"></recipient>
      </div>

      <div class="card__block card__block--actions" v-if="$store.getters['card/current'].type !== 'test'">
        <template v-if="!this.isFirstProduct">
          <div class="card__buttons" v-if="$store.getters['card/current'].id === null">
            <default-btn white class="widget-action--add-another-certificate-cancel"
              :class="{ 'button--grey': !hasFaces }" full-width :disabled="!hasFaces" @click.prevent="backToCart()">
              <span>{{ $t('form.buttons.cancel') }}</span>
            </default-btn>
          </div>
          <div class="card__buttons" v-if="$store.getters['card/current'].id !== null">
            <default-btn white @click.prevent="backToCart()" class="widget-action--cancel-edit">
              <span>{{ $t('form.buttons.cancel') }}</span>
            </default-btn>
            <default-btn @click.prevent="saveToCart()" class="widget-action--save-edit">
              <span>{{ $t('form.buttons.save_changes') }}</span>
            </default-btn>
          </div>
        </template>
      </div>

      <div class="card__block card__block--center" v-if="$store.getters['card/current'].type === 'test'">
        <div class="message message--success" v-html="$t('form.test_was_sent')" v-if="isShipped"></div>
        <div class="message message--error" v-if="error" v-html="error"></div>
        <button class="button button--action button--shadow button--large event__action--send-test"
          @click="sendTestCertificate" :disabled="isPending">
          <span v-if="!isPending">{{ $t('form.buttons.send') }}</span>
          <span v-if="isPending" v-html="$t('form.test_is_sending')"></span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import FaceSelector from '../components/FaceSelector'
import QuantitySelector from '../components/QuantitySelector'
import Recipient from '../components/Recipient'
import Slick from 'vue-slick'
import PixieEditor from '../components/PixieEditor'

import { removeEmoji, pluralize, numberFormat } from 'app/modules/utils'

import 'slick-carousel/slick/slick.css'
import DefaultBtn from '../components/default/DefaultBtn'
import ActivationInfo from './ActivationInfo'
import IconArrow from './icons/IconArrow'
import IconUploadImage from './icons/IconUploadImage'
import IconWarning from './icons/IconWarning'

import Text705 from './custom/Text705'

import { scrollIntoView } from 'seamless-scroll-polyfill'

export default {
  components: {
    IconUploadImage,
    IconArrow,
    IconWarning,
    DefaultBtn,
    ActivationInfo,
    'face-selector': FaceSelector,
    'quantity-selector': QuantitySelector,
    'recipient': Recipient,
    PixieEditor,
    Slick,
    Text705,
  },
  data() {
    return {
      editId: null,
      slickOptions: {
        dots: true,
        prevArrow: '.card-info-select__arrow--prev',
        nextArrow: '.card-info-select__arrow--next',
        slidesToShow: 1
      },
      slickImages: [],
      extendedTitle: '',
      regexp: /st.giftery.ru/
    }
  },
  computed: {
    product: function () {
      if (typeof this.$store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.$store.state.main.product
      }
    },
    isValid() {
      // return !!(this.model.title && this.model.text)
      return true
    },
    hasFaces() {
      return this.$store.state.main.faces && this.$store.state.main.faces.length !== 0
    },
    hasMultipleFaces() {
      return this.$store.state.main.faces && this.$store.state.main.faces.length > 1
    },
    isFirstProduct() {
      if (typeof this.$store.state.cart !== 'undefined') {
        return this.$store.state.cart.positions.length < 1
      } else {
        return true
      }
    },
    commissionValue() {
      const multiplier =
        this.product &&
        this.product.properties &&
        this.product.properties.face_multiplier

      if (multiplier && multiplier > 1) {
        return numberFormat(this.$store.state.card.current.face * multiplier, 0, ',', ' ') + ' ' + this.$store.state.region.currency
      }

      return 0
    },
    commissionPercent() {
      const multiplier =
        this.product &&
        this.product.properties &&
        this.product.properties.face_multiplier

      if (multiplier && multiplier > 1) {
        return (Math.abs(multiplier - 1) * 100).toFixed()
      }

      return 0
    },
  },
  watch: {
    '$store.state.main.product.images': {
      handler(val) {
        const uploadedImages = this.$store.state.card.uploadedImages[this.product.id]
        this.slickImages = uploadedImages && uploadedImages.length > 0 ? [...val, ...uploadedImages] : [...val]
        if (this.$refs.slick) this.reInit()
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch('main/setPreload', false)

    this.$nextTick(() => {
      this.sendYandexEcommerceDetail()
    })
  },
  methods: {
    next() {
      this.$refs.slick.next()
    },
    prev() {
      this.$refs.slick.prev()
    },
    clickOnUploadMyImage() {
      if (this.$refs.slick.currentSlide() === this.slickImages.length) {
        this.selectFile()
      } else {
        this.$refs.slick.goTo(this.slickImages.length)
      }
    },
    reInit() {
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
      })
    },
    handleAfterChange(event, slick, currentSlide) {
      let image = this.slickImages[currentSlide]

      if (!image) {
        image = this.slickImages[0]
      }

      this.$store.dispatch('card/setCardImage', image)
    },
    backToCart() {
      this.$router.push({ name: 'Cart' })
    },
    saveToCart() {
      if (this.validate()) {
        this.addPosition()
        this.$router.push({ name: 'Cart' })
      }
    },
    addToCart() {
      if (this.validate()) {
        this.addPosition()
      }
    },
    addToCartAndPay() {
      if (this.validate()) {
        this.addPosition()
        this.sendYandexEcommerceAdd()
        if (this.$store.state.main.options.code === 'askona-test') {
          this.sendAskonaAdd()
        }
        if (window.yaCounter33711084) {
          if (this.product.id === 17364) {
            window.ym(33711084, 'reachGoal', 'to_basket')
          }
        }
        this.$router.push({ name: 'Cart' })
      } else {
        this.$nextTick(() => {
          const firstError = document.querySelector('.field__error-message')

          if (firstError) {
            scrollIntoView(firstError, {
              block: 'center',
              behavior: 'smooth',
              inline: 'center'
            })
          }
        })
      }
    },
    addPosition() {
      if (this.$store.state.card.current.type === 'myself') {
        this.$store.dispatch('card/setGreeting', null)
        this.$store.dispatch('card/setSenderName', null)
        this.$store.dispatch('card/setRecipientName', null)
        this.$store.dispatch('card/setSenderEmail', null)
        this.$store.dispatch('card/setSenderPhone', null)
      }

      if (this.$store.state.card.current.sendExactTime === false) {
        this.$store.dispatch('card/setSendTime', null)
      }

      if (!this.$store.state.card.addVideo) {
        this.$store.dispatch('card/setVideoUid', null)
        this.$store.dispatch('card/setVideoUrl', null)
      }
      this.$store.commit('card/setGreeting', removeEmoji(this.$store.state.card.current.greeting))
      this.$store.dispatch('cart/addPosition', this.$store.state.card.current)
    },
    validate() {
      this.$refs.recipient.$v.$touch()

      if (this.$refs.recipient.$refs.sendTime) {
        this.$refs.recipient.$refs.sendTime.$v.$touch()

        return !this.$refs.recipient.$v.$invalid && !this.$refs.recipient.$refs.sendTime.$v.$invalid
      } else {
        return !this.$refs.recipient.$v.$invalid
      }
    },
    selectFile() {
      this.$refs.pixie.$refs.file.click()
    },
    imageUploaded(img) {
      this.slickImages.push(img)
      this.reInit()
      this.$nextTick(() => {
        if (this.slickImages.length > this.slickOptions.slidesToShow) this.$refs.slick.goTo(this.slickImages.length - 1)
      })
      this.$store.dispatch('card/setCardImage', img)
      this.$store.dispatch('card/setUploadedImages', { productId: this.product.id, img })
    },
    sendYandexEcommerceAdd() {
      // Giftery Data Layer for E-commerce
      const currentCart = this.$store.state.card.current
      const title = this.product.title ? this.product.title : ''

      if (window.dataLayer) {
        window.dataLayer.push({
          ecommerce: {
            currencyCode: this.$store.state.region.currencyCode,
            add: {
              products: [
                {
                  id: currentCart.productId,
                  name: title,
                  price: currentCart.face,
                  category: 'Виджет',
                  quantity: currentCart.quantity,
                  variant: currentCart.type === 'friend' ? 'Отправить в подарок' : 'Отправить себе'
                }
              ]
            },
          }
        })
      }
    },
    sendAskonaAdd() {
      try {
        const currentCart = this.$store.state.card.current
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          "event": "add",
          "ecommerce": {
            "currencyCode": "RUB",
            "add": {
              "products": [{
                "id": currentCart.productId + '_' + currentCart.face,
                "name" : "Подарочный сертификат",
                "price": currentCart.face,
                "discount": 0,
                "brand": "Askona",
                "category": "Сертификаты",
                "coupon": "Добавление в корзину - сертификат",
                "variant" : currentCart.face + " р",
                "list": "/landing/gift_certificates/",
                "position": 0,
                "quantity": currentCart.quantity
              }]
            }
          }
        });
        window.ym(1383371, 'reachGoal', 'addTocart', {"item": "certificate", "page_path": "/landing/gift_certificates/"});
      } catch (e) {
        console.error(e)
      }
    },
    sendYandexEcommerceDetail() {
      // Giftery Data Layer for E-commerce
      if (window.dataLayer) {
        window.dataLayer.push({
          ecommerce: {
            currencyCode: this.$store.state.region.currencyCode,
            detail: {
              products: [
                {
                  id: this.product.productId,
                  name: this.product.title,
                  category: 'Виджет',
                }
              ]
            },
          }
        })
      }
    },
    setDescription(data) {
      this.extendedTitle = data
    },
    pluralize(...args) {
      return pluralize.apply(this, args)
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;

  &__top {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: rem(20);
    padding: rem(40) rem(20);
    margin-bottom: rem(20);
  }

  &__block-header {
    font-size: rem(13);
    color: #6E7882;
    line-height: rem(16);
    text-align: center;
    margin-top: rem(34);

    &::v-deep {
      .btn {
        color: #6E7882;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-top: rem(10);

    &::v-deep {
      .btn {
        width: 100%;
        margin-top: rem(10);
      }
    }
  }
}

.card-info {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  &__right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: rem(40);

    &-padding-bottom {
      padding-bottom: rem(54);
    }
  }

  &__title {
    font-size: rem(13);
    line-height: 120%;
    color: #666666;
    margin-bottom: rem(10);
  }

  &__name {
    font-weight: 600;
    font-size: rem(16);
    line-height: 120%;
    color: #0E1E0E;
    padding-right: rem(25);
    margin-bottom: rem(30);

    &-sub {
      font-size: rem(13);
      line-height: rem(18);
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-child {
      .card-info__block-header {
        justify-content: center;
      }
    }
  }

  &__block-container {
    display: flex;
    justify-content: space-between;
  }

  &__block-header, &__block-notation {
    font-size: rem(13);
    line-height: rem(16);
    color: #6E7882;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-bottom: rem(10);
  }

  &__block-notation {
    margin:  rem(10) 0 0;

    span::first-letter {
      text-transform: uppercase;
    }
    a {
      color:var(--theme-reserve-primary-background-component);
      text-decoration: underline;
      font-weight: bold;
    }
    .widget-tooltip__content {
      z-index: 1;
      left: calc(100% + 15px);
      top: 0;
      transform: translate(0, -25%) !important;
      padding: rem(14) rem(20);
      height: max-content;
      width: max-content;
      max-width: max-content;
      line-height: 120%;
      background: white;
      color: #0B132A;
      border-radius: 10px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 10px 10px 10px rgba(110, 120, 130, 0.05), 20px 10px 80px rgba(110, 120, 130, 0.1);
      &::before {
        transform: translate(0, -50%);
        top: 50%;
        right: 100%;
        border-top-color: transparent;
        border-right-color: white;
        border-width: 8px;
      }

      &:after {
        content: '';
      }

      &-text::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__block-content {
    height: rem(40);
    display: flex;
    align-items: center;
  }

  &__warning {
    font-size: rem(15);
    color: #fa6400;
  }
}

.card-select-image-multiple {
  height: rem(184);
  position: relative;

  &__container {
    height: rem(184);
    position: relative;
  }

  &:hover {
    .card-info-select__arrows {
      opacity: 1;
    }
  }

  &::v-deep {
    &:hover {
      .slick-list::after {
        opacity: 1;
      }
    }
  }
}

.card-info-select {
  &__arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }

  &__arrow {
    width: rem(85);
    height: 100%;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    pointer-events: auto;
    transition: 0.3s;

    &--prev {
      padding-right: rem(40);
    }

    &--next {
      padding-left: rem(40);
    }
  }
}

.card-select-image {
  width: rem(400);
  position: relative;

  &__discount {
    width: rem(63);
    height: rem(58);
    position: absolute;
    top: rem(-12);
    right: rem(-22);
    border-radius: 0;
    padding: 0;
    z-index: 1;

    &-text {
      width: 100%;
      height: 98%;
      font-weight: 700;
      font-style: italic;
      font-size: rem(10);
      line-height: 125%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;

      &-value {
        font-size: rem(15);
      }
    }
  }

  img {
    max-width: 100%;
  }

  &__single {
    border-radius: rem(20);
    box-shadow: 0 0 rem(40) rgba(110, 120, 130, 0.1);
  }


  &__image {
    width: rem(400);
    height: rem(182);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: rem(10);
  }

  &__custom {
    &-text {
      font-weight: 500;
      font-size: rem(16);
      color: var(--theme-primary-text-color-component);
      margin-top: rem(20);
      margin-bottom: rem(10);
    }
  }

  &__custom-image-gift {
    width: rem(400);
    height: rem(182);
    background: var(--theme-primary-background-component);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    .card-select-image__image {
      background-size: auto;
    }
  }
}

.card-select-image::v-deep {
  .slick-initialized {
    pointer-events: none;
  }

  .slick-list {
    width: rem(400);
    height: rem(182);
    overflow: hidden;
    position: relative;
    border-radius: rem(10);
    box-shadow: 0 0 rem(40) rgba(110, 120, 130, 0.1);
    pointer-events: initial;
    transition: 0.2s;

    &::after {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.55);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  .slick-dots {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: rem(20) 0 rem(10);

    li {
      width: rem(6);
      height: rem(6);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(6);

      &:last-child {
        margin-right: 0;
      }
    }

    button {
      width: rem(6);
      height: rem(6);
      background-color: #E9EBEC;
      font-size: 0;
      border-radius: 50%;
      padding: 0;
      border: none;
      appearance: none;
      cursor: pointer;
      transition: 0.3s;
      pointer-events: initial;
    }

    .slick-active {
      width: rem(16);
      border-radius: rem(4);
      transition: 0.3s;

      button {
        width: rem(16);
        background: var(--theme-primary-background-component);
        border-radius: rem(4);
      }
    }
  }
}

.widget-tooltip {
  position: relative;
  appearance: none;
  margin-left: rem(6);
  margin-bottom: rem(2);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    max-width: rem(133);
    min-width: rem(133);
    background: #ffffff;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0B132A;
    text-align: center;
    position: absolute;
    left: rem(8);
    bottom: rem(35);
    opacity: 0;
    border-radius: rem(10);
    padding: rem(10) rem(20);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    @include mobile {
      top: rem(-30);
      right: rem(-43);
      bottom: auto;
      left: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      right: rem(54);
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #ffffff;
      transform: translate(-50%, 0);

      @include mobile {
        top: rem(32);
        right: rem(-19);
        border-top-color: transparent;
        border-left-color: #ffffff;
      }
    }
  }

  &:hover {
    cursor: help;

    .widget-tooltip__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.link {
  background-color: transparent;
  color: #0E1E0E;
  text-decoration: underline;
  border: none;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}
</style>
