<template>
  <div class="basket">
    <div class="basket-empty" v-if="$store.getters['cart/quantity'] === 0">
      <div class="basket-empty__header">
        <div class="basket-empty__title">
          {{ $t('cart.empty_cart_title') }}
        </div>
      </div>
      <div class="basket-empty__content">
        <div class="basket-empty__text">
          <span v-html="$t(`cart.empty_cart_desc.${ $store.state.main.product.kind }`)"></span>
        </div>
        <div class="basket-empty__btns">
          <default-btn :to="{ name: 'AddCard' }" class="widget-action--add-certificate">
            <span>{{ $t(`form.buttons.add_certificate.${ $store.state.main.product.kind }`) }}</span>
          </default-btn>
        </div>
      </div>
    </div>

    <div class="basket__container" v-if="$store.getters['cart/quantity'] > 0" sticky-container>
      <div class="basket__content">

        <div class="basket-grid">
          <div class="basket-grid-content">
            <div class="basket-grid-content-item"
              v-for="(positionsBySender, senderKey) in $store.getters['cart/positionsGroupedBySenderAndImage']"
              :key="senderKey">
              <template v-if="positionsBySender.group.positions[0].type !== 'myself'">
                <div class="basket-grid-content-item__mobile-image">
                  <img :src="positionsBySender.image" alt="" />
                </div>
                <div class="basket-grid-content-item__mobile">
                  <div class="basket-grid-header">
                    <div class="basket-grid-header__item">{{ $t('cart.sender') }}</div>
                    <div class="basket-grid-header__item">
                      {{ $t(`cart.certificate.${ $store.state.main.product.kind }`) }}
                    </div>
                    <div class="basket-grid-header__item">{{ $t('cart.brand') }}</div>
                    <div class="basket-grid-header__item" v-if="faceMultiplier !== 1">
                      <template v-if="faceMultiplier > 1">
                        {{ $t('cart.commission') }}
                      </template>
                      <template v-if="faceMultiplier < 1">
                        {{ $t('cart.discount') }}
                      </template>
                    </div>
                  </div>
                  <div class="cart-item-sender cart-item-sender--no-border-radius">
                    <div class="cart-item-sender__item">
                      <div class="cart-item-sender__name">
                        <template v-if="positionsBySender.group.name">{{ positionsBySender.group.name }}</template>
                        <template v-else>{{ $t('cart.name_not_specified') }}</template>
                      </div>
                      <div class="cart-item-sender__email">
                        <div class="cart-item-sender__email-text" :title="positionsBySender.group.email">
                          {{ positionsBySender.group.email }}
                        </div>
                      </div>
                    </div>
                    <div class="cart-item-sender__item">
                      <div class="cart-item-sender__item-image">
                        <img :src="positionsBySender.image" alt="" />
                      </div>
                    </div>
                    <div class="cart-item-sender__item">
                      {{ product.title }}
                    </div>
                    <div class="cart-item-sender__item">
                      <span class="cart-item-sender__item-discount" v-if="faceMultiplier !== 1">
                        <template v-if="faceMultiplier > 1">
                          {{ Math.abs(((1 - faceMultiplier) * 100).toFixed()) }} %
                        </template>
                        <template v-if="faceMultiplier < 1">
                          {{ ((1 - faceMultiplier) * 100).toFixed() }} %
                        </template>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="cart-item-recipient">
                  <div class="cart-item-recipient__header">
                    <div class="cart-item-recipient__header-item">{{ $t('cart.recipient') }}</div>
                    <div class="cart-item-recipient__header-item">{{ $t('cart.face') }}</div>
                    <div class="cart-item-recipient__header-item">{{ $t('cart.quantity') }}</div>
                    <div class="cart-item-recipient__header-item">
                      <div>{{ $t('cart.send') }}</div>
                      <div class="cart-item-recipient-tip">
                        <div class="cart-item-recipient-tip__icon">
                          <IconWarning />
                        </div>
                        <div class="cart-item-recipient-tip__content" tabindex="-1" v-if="!isPayVisible">
                          <div class="cart-item-recipient-tip__content-text">
                            <div>{{ $t('form.date.timezone') }}</div>
                            <div>{{ timeZoneText }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="cart-item-recipient-item" v-for="(position, index) in positionsBySender.group.positions"
                    :key="index">
                    <div class="cart-item-recipient-item__block">
                      <div class="cart-item-recipient__name">
                        <template v-if="position.recipientName">{{ position.recipientName }}</template>
                        <template v-else>{{ $t('cart.name_not_specified') }}</template>
                      </div>
                      <div class="cart-item-recipient__email" v-if="position.recipientEmail">
                        <div class="cart-item-recipient__email-text" :title="position.recipientEmail">
                          {{ position.recipientEmail }}
                        </div>
                      </div>
                      <div class="cart-item-recipient__phone" v-if="position.recipientPhone">{{ position.recipientPhone
                      }}</div>
                    </div>
                    <div class="cart-item-recipient-item__block" :data-label="$t('cart.face')">
                      <div>
                        <div class="">{{ position.face | numberFormat(0, '', ' ') }}<span>&nbsp;{{
                            $store.state.region.currency
                        }}</span></div>
                      </div>
                    </div>
                    <div class="cart-item-recipient-item__block" :data-label="$t('cart.quantity')">
                      <div v-if="!isPayVisible">
                        <quantity-selector quantityType="cartQuantity" :id="position.id" />
                      </div>
                      <div v-if="isPayVisible">
                        {{ position.quantity }}
                      </div>
                    </div>
                    <div class="cart-item-recipient-item__block cart-item-recipient-item__block--time">
                      <div class="cart-item-recipient-item__block-time">
                        <div class="">
                          <span v-if="position.sendExactTime">{{ date(position.sendTime, $store.state.region.macroregion) }}</span>
                          <span v-else>{{ $t('cart.instant') }}</span>
                        </div>
                      </div>
                      <div class="cart-item-recipient-item__block-btns" data-label="Действия" v-if="!isPayVisible">
                        <div>
                          <default-btn round @click="editPosition(position.id)" :title="$t('cart.edit_card')"
                            class="widget-action--edit-certificate">
                            <IconEdit />
                          </default-btn>
                          <default-btn round @click="removePosition(position.id)" :title="$t('cart.remove_card')"
                            class="widget-action--remove-certificate">
                            <IconDelete />
                          </default-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>


              <template v-else>
                <div class="basket-grid-content-item__mobile-image">
                  <img :src="positionsBySender.image" alt="" />
                </div>
                <div class="basket-grid-content-item__mobile">
                  <div class="basket-grid-header">
                    <div class="basket-grid-header__item">{{ $t('form.send_to_myself') }}</div>
                    <div class="basket-grid-header__item">
                      {{ $t(`cart.certificate.${ $store.state.main.product.kind }`) }}
                    </div>
                    <div class="basket-grid-header__item">{{ $t('cart.face') }}</div>
                    <div class="basket-grid-header__item">{{ $t('cart.quantity') }}</div>
                  </div>
                  <div class="cart-item-sender" v-for="(position, index) in positionsBySender.group.positions"
                    :key="index">
                    <div class="cart-item-sender__item">
                      <div class="cart-item-sender__name" v-if="position.recipientName">
                        {{ position.recipientName }}
                      </div>
                      <div class="cart-item-recipient__email" v-if="position.recipientEmail">
                        <div class="cart-item-recipient__email-text" :title="position.recipientEmail">
                          {{ position.recipientEmail }}
                        </div>
                      </div>
                    </div>
                    <div class="cart-item-sender__item">
                      <div class="cart-item-sender__item-image">
                        <img :src="position.cardImage" alt="" />
                      </div>
                    </div>
                    <div class="cart-item-sender__item cart-item-sender__item-face">
                      <div>
                        <div v-if="faceMultiplier > 1">{{ position.face * faceMultiplier | numberFormat(0, '', ' ') }}
                          <span>&nbsp;{{ $store.state.region.currency }}</span>
                          <div class="widget-tooltip">
                            <div class="widget-tooltip__icon">
                              <IconWarning/>
                            </div>
                            <div class="widget-tooltip__content" tabindex="-1">
                              <div class="widget-tooltip__content-text">
                                <span> {{ $t('form.commission') }} {{ commissionPercent }}%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>{{ position.face | numberFormat(0, '', ' ') }}<span>&nbsp;{{ $store.state.region.currency }}</span></div>
                      </div>
                    </div>
                    <div class="cart-item-sender__item cart-item-sender__item--quantity">
                      <div class="cart-item-sender__item-quantity">
                        <div v-if="!isPayVisible">
                          <quantity-selector quantityType="cartQuantity" :id="position.id" />
                        </div>
                        <div v-if="isPayVisible">
                          {{ position.quantity }}
                        </div>
                      </div>
                      <div class="cart-item-sender__item-btns" data-label="Действия" v-if="!isPayVisible">
                        <div>
                          <default-btn round @click="editPosition(position.id)" :title="$t('cart.edit_card')"
                            class="widget-action--edit-certificate">
                            <IconEdit />
                          </default-btn>
                          <default-btn round @click="removePosition(position.id)" :title="$t('cart.remove_card')"
                            class="widget-action--remove-certificate">
                            <IconDelete />
                          </default-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>



        <div class="basket-grid-mobile">
          <div class="basket-grid-mobile__content">
            <div class="basket-grid-mobile-item"
              v-for="(positionsBySender, senderKey) in $store.getters['cart/positions']" :key="senderKey">
              <div class="basket-grid-mobile-item__image">
                <img :src="positionsBySender.cardImage" alt="" />
              </div>

              <div class="basket-grid-mobile-item__sender" v-if="positionsBySender.type !== 'myself'">
                <div class="basket-grid-mobile-item__sender-header">{{ $t('cart.sender') }}</div>
                <div class="basket-grid-mobile-item__sender-name">
                  <template v-if="positionsBySender.senderName">{{ positionsBySender.senderName }}</template>
                  <template v-else>
                    <template v-if="positionsBySender.type === 'myself'">{{ $t('cart.to_myself') }}</template>
                    <template v-else>{{ $t('cart.name_not_specified') }}</template>
                  </template>
                </div>
                <div class="basket-grid-mobile-item__sender-email" v-if="positionsBySender.senderEmail">{{
                    positionsBySender.senderEmail
                }}</div>
              </div>

              <div class="basket-grid-mobile-item__recipient">
                <div class="basket-grid-mobile-item__recipient-header">
                  <template v-if="positionsBySender.type === 'myself'">{{ $t('form.send_to_myself') }}</template>
                  <template v-else>{{ $t('cart.recipient') }}</template>
                </div>
                <div class="basket-grid-mobile-item__recipient-name" v-if="positionsBySender.recipientName">
                  <template v-if="positionsBySender.recipientName">{{ positionsBySender.recipientName }}</template>
                  <template v-else>{{ $t('cart.name_not_specified') }}</template>
                </div>
                <div class="basket-grid-mobile-item__recipient-email" v-if="positionsBySender.recipientEmail">{{
                    positionsBySender.recipientEmail
                }}</div>
                <div class="basket-grid-mobile-item__recipient-phone" v-if="positionsBySender.recipientPhone">{{
                    positionsBySender.recipientPhone
                }}</div>
              </div>



              <div class="basket-grid-mobile-item__time"
                v-if="!$store.state.main.isMobile || ($store.state.main.isMobile && positionsBySender.type !== 'myself')">
                <div class="basket-grid-mobile-item__time-header">
                  <span>{{ $t('cart.send') }}</span>
                  <span class="cart-item-recipient-tip">
                    <div class="cart-item-recipient-tip__icon">
                      <IconWarning />
                    </div>
                    <div class="cart-item-recipient-tip__content" tabindex="-1" v-if="!isPayVisible">
                      <div class="cart-item-recipient-tip__content-text">
                        <div>{{ $t('form.date.timezone') }}</div>
                        <div>{{ timeZoneText }}</div>
                      </div>
                    </div>
                    <div class="cart-item-recipient-tip__content-text">
                      <div>{{ timeZoneText }}</div>
                    </div>
                  </span>
                </div>

                <div class="basket-grid-mobile-item__time-text">
                  <div class="">
                    <span v-if="positionsBySender.sendExactTime">{{ date(positionsBySender.sendTime, $store.state.region.macroregion)
                    }}</span>
                    <span v-else>{{ $t('cart.instant') }}</span>
                  </div>
                </div>
              </div>



              <div class="basket-grid-mobile-item__control">
                <div class="basket-grid-mobile-item__control-face" :data-label="$t('cart.face')">
                  <div class="basket-grid-mobile-item__control-header">{{ $t('cart.face') }}</div>
                  <div class="mobile-weight-500">
                    <div class="basket-grid-mobile-item__control-content">{{ positionsBySender.face | numberFormat(0,
                        '', ' ')
                    }}<span>&nbsp;{{ $store.state.region.currency }}</span></div>
                  </div>
                </div>
                <div class="basket-grid-mobile-item__control-separator"></div>
                <div class="basket-grid-mobile-item__control-quantity" :data-label="$t('cart.quantity')">
                  <div class="basket-grid-mobile-item__control-header">{{ $t('cart.quantity') }}</div>
                  <div v-if="!isPayVisible">
                    <quantity-selector quantityType="cartQuantity" :id="positionsBySender.id" />
                  </div>
                  <div v-if="isPayVisible">
                    {{ positionsBySender.quantity }}
                  </div>
                </div>
              </div>

              <div class="basket-grid-mobile-item__btns" data-label="Действия" v-if="!isPayVisible">
                <default-btn white @click="removePosition(positionsBySender.id)" :title="$t('cart.remove')"
                  class="widget-action--edit-certificate">
                  {{ $t('cart.remove') }}
                </default-btn>
                <default-btn white @click="editPosition(positionsBySender.id)" :title="$t('cart.edit_card')"
                  class="widget-action--remove-certificate">
                  {{ $t('cart.edit_card') }}
                </default-btn>
              </div>

              <div class="basket-grid-mobile-item__separator"></div>
            </div>
          </div>
        </div>
      </div>

      <PromoCode v-if="$store.state.main.options.allowPromoCodes" />


      <template v-if="$store.getters['cart/quantity'] <= $store.state.main.maxCartCount">
        <div class="basket__bottom">
          <div class="basket__footer">
            <div class="basket__footer-col">
              <default-btn v-if="$store.getters['cart/quantity'] < $store.state.main.maxCartCount"
                @click.prevent="addAnotherCard()" white big
                class="basket-btn-tip widget-action--add-another-certificate">
                <span v-html="$t('cart.add_another_card')"></span>
                <div class="basket-btn-tip__content" tabindex="-1" v-if="!isPayVisible">
                  <div class="basket-btn-tip__content-text" v-html="$t('cart.add_another_card_tip')"></div>
                </div>
              </default-btn>
            </div>
            <div class="basket__footer-col --block-pay">
              <div class="basket__footer-col">
                <div class="basket-sum">
                  <div class='basket-sum__wrapper'>
                    <div class="basket-sum__discount">
                      {{ $t('cart.total') }}:
                      <span class="basket-sum__discount-percent" v-if="faceMultiplier < 1">
                        <!--На случай, если потребуется вернуть скидку в рублях-->
                        <!--{{ $store.getters['cart/sum'] | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}-->
                        &ndash;{{ ((1 - faceMultiplier) * 100).toFixed() }} %
                      </span>
                    </div>
                    <div class="basket-sum__total">
                      <span class="basket-sum__total-old" v-if="faceMultiplier < 1">
                        {{ $store.getters['cart/sum'] | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}
                      </span>
                      <span class="basket-sum__total-new" v-if="faceMultiplier > 1">
                        {{ $store.getters['cart/sum'] * faceMultiplier | numberFormat(0, '', ' ')
                        }}&nbsp;{{ $store.state.region.currency }}
                      </span>
                      <span class="basket-sum__total-new" v-else>
                        {{ $store.getters['cart/sum'] | setDiscount(faceMultiplier) | numberFormat(0, '', ' ')
                        }}&nbsp;{{ $store.state.region.currency }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="basket__footer-col">
                <div class="backet-pay">
                  <div class="basket-pay__item">
                    <div class="backet-pay-radio__radios">

                      <div class="backet-pay-radio" :class="{ 'backet-pay-radio--checked': paymentGate === 'card' }">
                        <label class="widget-action--send-payment-gate-card">
                          <input type="radio" v-model="paymentGate" name="backet-pay-type" value="card"
                            id="backet-pay-type-card" />
                          <div class="backet-pay-radio__checkmark">
                            <IconCheckboxAroundChecked v-if="paymentGate === 'card'" />
                            <IconCheckboxAround v-else />
                          </div>
                          <div class="backet-pay-radio__content">
                            <div class="backet-pay-radio__content-text">Банковская карта</div>
                            <div class="backet-pay-radio__content-icons">
                              <slot name="social">
                                <div class="basket-payments">
                                  <div class="basket-payments__item">
                                    <ImgMastercard />
                                  </div>
                                  <div class="basket-payments__item">
                                    <ImgVisa />
                                  </div>
                                  <div class="basket-payments__item" v-if="$store.state.region.macroregion === 'ru'">
                                    <ImgMir />
                                  </div>
                                  <div class="basket-payments__item" v-if="$store.state.region.macroregion === 'es'">
                                    <ImgPayPal />
                                  </div>
                                </div>
                              </slot>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="backet-pay-radio"
                        :class="{ 'backet-pay-radio--checked': paymentGate === 'tinkoffsbp' }">
                        <label class="widget-action--send-payment-gate-sbp">
                          <input type="radio" v-model="paymentGate" name="backet-pay-type" value="tinkoffsbp"
                            id="backet-pay-type-sbp" />
                          <div class="backet-pay-radio__checkmark">
                            <IconCheckboxAroundChecked v-if="paymentGate === 'tinkoffsbp'" />
                            <IconCheckboxAround v-else />
                          </div>
                          <div class="backet-pay-radio__content">
                            <div class="backet-pay-radio__content-text">Система быстрых платежей</div>
                            <div class="backet-pay-radio__content-icons">
                              <slot name="social">
                                <div class="basket-payments">
                                  <div class="basket-payments__item">
                                    <ImgSbp />
                                  </div>
                                </div>
                              </slot>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="backet-pay-radio" :class="{ 'backet-pay-radio--checked': paymentGate === 'sberbank' }"
                        v-if="false">
                        <label class="widget-action--send-payment-gate-sbp">
                          <input type="radio" v-model="paymentGate" name="backet-pay-type" value="sberbank"
                            id="backet-pay-type-sbp" />
                          <div class="backet-pay-radio__checkmark">
                            <IconCheckboxAroundChecked v-if="paymentGate === 'sberbank'" />
                            <IconCheckboxAround v-else />
                          </div>
                          <div class="backet-pay-radio__content">
                            <div class="backet-pay-radio__content-text">Сбербанк Онлайн</div>
                            <div class="backet-pay-radio__content-icons">
                              <slot name="social">
                                <div class="basket-payments">
                                  <div class="basket-payments__item">
                                    <ImgSberPay />
                                  </div>
                                </div>
                              </slot>
                            </div>
                          </div>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="basket-agree" v-if="!isPayVisible">
            <div class="basket-agree__cell"></div>
            <div class="basket-agree__cell">
              <div class="basket-agree__text" v-if="$store.state.region.macroregion === 'kz'">
                <span v-html="$t('cart.agree.pressing_button')"></span>&nbsp;<default-btn
                  v-if="!($store.state.main.options.showOfferLink === false)" @click.prevent="goTo('Offer')"
                  v-html="$t('cart.agree.agreement')" :href="'#'" is-link></default-btn>,&nbsp;<default-btn
                  @click.prevent="goTo('Conditions')" v-html="$t('cart.agree.terms')" :href="'#'" is-link></default-btn>
                <span v-if="!($store.state.main.options.showOfferLink === false)"
                  v-html="$t('cart.agree.accepting')"></span>&nbsp;<default-btn
                  :href="'https://st.giftery.ru/docs/kz/Politika_provedeniya_platezhei_CloudPayments.docx'"
                  :target="'_blank'" v-html="$t('cart.agree.payment_processing')" is-link></default-btn>.
              </div>
              <div class="basket-agree__text" v-else>
                <div
                  class="widget-input-checkbox"
                  :class="{ 'widget-input-checkbox--active': checkedOffer }"
                >
                  <div class='widget-input-checkbox__wrapper'>
                    <input class="widget-input-checkbox__input"
                      id="checkedPhoneNumberBlockEmail" v-model="checkedOffer" type="checkbox" />
                    <label for="checkedPhoneNumberBlockEmail">
                      <div class="widget-input-checkbox__icon">
                        <IconCheckboxChecked v-if="checkedOffer" />
                        <IconCheckbox v-else />
                      </div>
                      <span>
                        <span v-html="$t('cart.agree.i_agree')"></span>&nbsp;<default-btn
                          v-if="!($store.state.main.options.showOfferLink === false)" @click.prevent="goTo('Offer')"
                          v-html="$t('cart.agree.agreement')" :href="'#'" is-link></default-btn> <span v-html="$t('cart.agree.and_accept')"></span>&nbsp;<default-btn
                          :href="'https://st.giftery.ru/docs/legal/ru/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'"
                          :target="'_blank'" v-html="$t('cart.agree.privacy_policy')" is-link></default-btn> <span v-html="$t('cart.agree.accepting')"></span>&nbsp;<default-btn
                          :href="'https://st.giftery.ru/docs/legal/ru/%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5.pdf'"
                          :target="'_blank'" v-html="$t('cart.agree.additional_agreement')" is-link></default-btn>.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="basket-buttons">
            <div class="basket-buttons__cell"></div>
            <div class="basket-buttons__cell">
              <default-btn
                v-if="!isPayVisible"
                :disabled="isProcessing"
                @click.prevent="pay()"
                class="widget-action--pay">
                <span v-html="$t('cart.go_to_the_payment')"></span>
              </default-btn>
            </div>
          </div>
        </div>


        <div class="basket__bottom basket__bottom--mobile">
          <div class="basket__footer">
            <div class="basket__footer-block">
              <div class="basket-sum-mobile">
                <div class='basket-sum-mobile__wrapper'>
                  <div class="basket-sum-mobile__discount">
                    {{ $t('cart.total') }}:
                    <span class="basket-sum-mobile__discount-percent" v-if="faceMultiplier < 1">
                      <!--На случай, если потребуется вернуть скидку в рублях-->
                      <!--{{ $store.getters['cart/sum'] | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}-->
                      &ndash;{{ ((1 - faceMultiplier) * 100).toFixed() }} %
                    </span>
                  </div>
                  <div class="basket-sum-mobile__total">
                    <span class="basket-sum-mobile__total-old" v-if="faceMultiplier < 1">
                      {{ $store.getters['cart/sum'] | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}
                    </span>
                    <span class="basket-sum-mobile__total-new" v-if="faceMultiplier > 1">
                      {{ $store.getters['cart/sum'] * faceMultiplier | numberFormat(0, '', ' ') }}&nbsp;{{
                          $store.state.region.currency
                      }}
                    </span>
                    <span class="basket-sum-mobile__total-new">
                      {{ $store.getters['cart/sum'] | setDiscount(faceMultiplier) | numberFormat(0, '', ' ') }}&nbsp;{{
                          $store.state.region.currency
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="basker-mobile__separator"></div>

            <div class="basket-payment-mobile">
              <div class="basket-payment-mobile__title">Способ оплаты:</div>
              <div class="basket-payment-mobile__items">
                <div class="basket-payment-mobile-item"
                  :class="{ 'basket-payment-mobile-item--checked': paymentGate === 'tinkoffsbp' }">
                  <label class="widget-action--send-payment-gate-sbp-mobile">
                    <input type="radio" v-model="paymentGate" name="backet-pay-type" value="tinkoffsbp"
                      id="backet-pay-type-sbp" />
                    <div class="basket-payment-mobile-item__content">
                      <div class="basket-payment-mobile-item__content-icons">
                        <slot name="social">
                          <div class="basket-payments">
                            <div class="basket-payments__item" v-if="$store.state.region.macroregion === 'ru'">
                              <ImgSbp />
                            </div>
                          </div>
                        </slot>
                      </div>
                      <div class="basket-payment-mobile-item__content-text">Оплата через СБП</div>
                    </div>
                  </label>
                </div>

                <div class="basket-payment-mobile-item"
                  :class="{ 'basket-payment-mobile-item--checked': paymentGate === 'card' }">
                  <label class="widget-action--send-payment-gate-card-mobile">
                    <input type="radio" v-model="paymentGate" name="backet-pay-type" value="card"
                      id="backet-pay-type-card" />
                    <div class="basket-payment-mobile-item__content">
                      <div class="basket-payment-mobile-item__content-icons">
                        <slot name="social">
                          <div class="basket-payments">
                            <div class="basket-payments__item">
                              <ImgMastercard />
                            </div>
                          </div>
                        </slot>
                      </div>
                      <div class="basket-payment-mobile-item__content-text">Банковская карта</div>
                    </div>
                  </label>
                </div>

                <div class="basket-payment-mobile-item"
                  :class="{ 'basket-payment-mobile-item--checked': paymentGate === 'sberbank' }" v-if="false">
                  <label class="widget-action--send-payment-gate-card-mobile">
                    <input type="radio" v-model="paymentGate" name="backet-pay-type" value="sberbank"
                      id="backet-pay-type-sberbank" />
                    <div class="basket-payment-mobile-item__content">
                      <div class="basket-payment-mobile-item__content-icons">
                        <slot name="social">
                          <div class="basket-payments">
                            <div class="basket-payments__item">
                              <ImgSberPay />
                            </div>
                          </div>
                        </slot>
                      </div>
                      <div class="basket-payment-mobile-item__content-text">Сбербанк Онлайн</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div class="basket__footer-block">
              <div class="basket-agree" v-if="!isPayVisible">
                <div class="basket-agree__cell"></div>
                <div class="basket-agree__cell">
                  <div class='basket-agree__text'>
                    <div
                      class="widget-input-checkbox"
                      :class="{ 'widget-input-checkbox--active': checkedOffer }"
                    >
                      <div class='widget-input-checkbox__wrapper'>
                        <input class="widget-input-checkbox__input"
                          id="checkedPhoneNumberBlockEmail" v-model="checkedOffer" type="checkbox" />
                        <label for="checkedPhoneNumberBlockEmail">
                          <div class="widget-input-checkbox__icon">
                            <IconCheckboxChecked v-if="checkedOffer" />
                            <IconCheckbox v-else />
                          </div>
                          <span>
                            <span v-html="$t('cart.agree.i_agree')"></span>&nbsp;<default-btn
                              v-if="!($store.state.main.options.showOfferLink === false)" @click.prevent="goTo('Offer')"
                              v-html="$t('cart.agree.agreement')" :href="'#'" is-link></default-btn> <span v-html="$t('cart.agree.and_accept')"></span> <default-btn
                              :href="'https://st.giftery.ru/docs/legal/ru/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'"
                              :target="'_blank'" v-html="$t('cart.agree.privacy_policy')" is-link></default-btn><br><span v-html="$t('cart.agree.accepting')"></span>&nbsp;<default-btn
                              :href="'https://st.giftery.ru/docs/legal/ru/%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5.pdf'"
                              :target="'_blank'" v-html="$t('cart.agree.additional_agreement')" is-link></default-btn>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="basket__footer-block">
              <div class="basket__footer-col">
                <default-btn v-if="!isPayVisible && $store.getters['cart/quantity'] < $store.state.main.maxCartCount"
                  @click.prevent="addAnotherCard()" white big
                  class="basket-btn-tip widget-action--add-another-certificate">
                  <span v-html="$t('cart.add_another_card')"></span>
                  <div class="basket-btn-tip__content" tabindex="-1" v-if="!isPayVisible">
                    <div class="basket-btn-tip__content-text" v-html="$t('cart.add_another_card_tip')"></div>
                  </div>
                </default-btn>
              </div>

            </div>
            <div class="basket__footer-block" v-if="isPayVisible">
              <default-btn @click.prevent="backToCards()" class="widget-action--back-to-form">
                <span v-html="$t('cart.back_to_form')"></span>
              </default-btn>
            </div>
            <div class="basket__footer-block" v-sticky="stickyEnabled" sticky-offset="{top: 10, bottom: 10}"
              sticky-side="bottom" on-stick="onStick" sticky-z-index="20">
              <div class="basket__footer-col">
                <default-btn :class="{ 'btn--sticked': btnSticked }" class="widget-action--pay" v-if="!isPayVisible"
                  :disabled="isProcessing" @click.prevent="btnSticked ? scrollToBasketFooter() : pay()">
                  <span v-html="$t('cart.go_to_the_payment')"></span>
                  <span v-if="btnSticked">
                    <template v-if="faceMultiplier > 1">
                      {{ $store.getters['cart/sum'] * faceMultiplier | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}
                    </template>
                    <template v-else>
                      {{ $store.getters['cart/sum'] | setDiscount(faceMultiplier) | numberFormat(0, '', ' ') }}&nbsp;{{ $store.state.region.currency }}
                    </template>
                  </span>
                </default-btn>
              </div>
            </div>

          </div>

        </div>
      </template>

      <div class="basket__b2b" v-if="$store.getters['cart/quantity'] >= $store.state.main.maxCartCount"
        v-html="$t('cart.max_quantity', { max: $store.state.main.maxCartCount })"></div>

      <!-- <div class="gc-alert"
           v-if="$store.state.region.macroregion === 'kz'"
      >
        <span>В&nbsp;связи с&nbsp;временными ограничениями платежного партнера CloudPayments.kz по&nbsp;приему онлайн платежей, карты Visa и&nbsp;Mastercard временно для оплаты не&nbsp;принимаются. Ограничения не&nbsp;распространяется на&nbsp;карты, выпущенные Сбербанком в&nbsp;Казахстане. Мы работаем над подключением альтернативного платежного партнера. Приносим свои извинения за&nbsp;временные неудобства.</span>
      </div> -->
    </div>

    <div class="basket-pay" v-if="isProcessing">
      <div class="basket-pay__loading-container">
        <div class="basket-pay__loading-container-text" v-html="$t('cart.please_wait_payment_processing')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import QuantitySelector from '../components/QuantitySelector'
import PromoCode from '../components/PromoCode'
import { Position } from 'app/models'
import { findIndex } from 'lodash'
import order from 'app/api/order'
import { availableErrors } from 'app/modules/app-error'
import { numberFormat, getSuccessUrl, getFailUrl } from 'app/modules/utils'
import { Settings } from 'luxon'
import { regionCloudPaymentsPublicId, paymentGate, timeZone } from 'app/modules/constants'
import { isInDST } from 'app/modules/datetime'
import { DateTime } from 'luxon'
import DefaultBtn from '../components/default/DefaultBtn'
import ModalAccess from '../components/modals/ModalAccess'
import ModalPaySBP from '../components/modals/ModalPaySBP'
import ModalPayFail from '../components/modals/ModalPayFail'

import IconEdit from '../components/icons/IconEdit'
import IconDelete from '../components/icons/IconDelete'
import IconWarning from '../components/icons/IconWarning'
import IconCheckbox from '../components/icons/IconCheckbox'
import IconCheckboxChecked from '../components/icons/IconCheckboxChecked'
import IconCheckboxAround from '../components/icons/IconCheckboxAround'
import IconCheckboxAroundChecked from '../components/icons/IconCheckboxAroundChecked'
import ImgMastercard from '../components/icons/ImgMastercard'
import ImgVisa from '../components/icons/ImgVisa'
import ImgMir from '../components/icons/ImgMir'
import ImgPayPal from '../components/icons/ImgPayPal'
import ImgSbp from '../components/icons/ImgSbp'
import ImgSberPay from '../components/icons/ImgSberPay'

import queryString from 'qs'
import { scrollIntoView } from 'seamless-scroll-polyfill'

export default {
  components: {
    ImgMir,
    ImgVisa,
    ImgMastercard,
    ImgPayPal,
    ImgSbp,
    ImgSberPay,
    DefaultBtn,
    QuantitySelector,
    PromoCode,
    IconEdit,
    IconDelete,
    IconWarning,
    IconCheckbox,
    IconCheckboxChecked,
    IconCheckboxAround,
    IconCheckboxAroundChecked
  },
  data() {
    return {
      stickyEnabled: true,
      btnSticked: true,
      isMessageVisible: false,
      isProcessing: false,
      iframeHeight: 210,
      isPayVisible: false,
      isDemoPayVisible: false,
      isOrderMade: false,
      success: '',
      error: '',
      regexp: /st.giftery.ru/,
      isInDST: false,
      paymentGate: 'card',
      checkedOffer: false,
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    setDiscount(face, faceMultiplier) {
      if (faceMultiplier < 1) {
        return face * faceMultiplier
      }
      return face
    },
  },
  watch: {
    'checkedOffer': function(condition) {
      if (condition) {
        this.hideOverlayCheckedOffer()
      }
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    orderId() {
      if (typeof this.$store.state.main.orderId !== 'undefined') {
        return this.$store.state.main.orderId
      } else {
        return {}
      }
    },
    faceMultiplier() {
      if (this.$store.state.cart.promoCode) {
        return 1 - this.$store.state.cart.promoCode.discount / 10000
      }
      else {
        return (this.product &&
          this.product.properties &&
          this.product.properties.face_multiplier) || 1
      }
    },
    timeZone() {
      if (this.isInDST) {
        return timeZone[this.$store.state.region.macroregion].zoneSummer
      }

      return timeZone[this.$store.state.region.macroregion].zone
    },
    timeZoneText() {
      const cities = this.$t('form.date.timezones')

      if (this.isInDST) {
        return cities[this.$store.state.region.macroregion] + '(' + timeZone[this.$store.state.region.macroregion].zoneSummer + ')'
      }

      return cities[this.$store.state.region.macroregion] + '(' + timeZone[this.$store.state.region.macroregion].zone + ')'
    },
    commissionPercent() {
      const multiplier =
        this.product &&
        this.product.properties &&
        this.product.properties.face_multiplier

      if (multiplier && multiplier > 1) {
        return (Math.abs(multiplier - 1) * 100).toFixed()
      }

      return 0
    },
  },
  mounted() {
    Settings.defaultLocale = this.$store.state.main.locale

    if (!document.getElementById('cloudpayments-widget-script')) {
      // CloudPayments pay widget script
      const cpScript = document.createElement('script')
      cpScript.type = 'text/javascript'
      if (this.$store.state.region.macroregion === 'ru' && this.$store.state.main.options?.psOverride === 'cloudpayments') {
        cpScript.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments/'
      } else if (this.$store.state.region.macroregion === 'kz') {
        cpScript.src = 'https://widget.tiptoppay.kz/bundles/widget.js'
      }
      cpScript.id = 'cloudpayments-widget-script'

      document.getElementsByTagName('head')[0].appendChild(cpScript)
    }

    this.isOrderMade = false
    if (this.$store.getters['cart/quantity'] === 0) {
      this.isMessageVisible = true
      this.messageText = this.$t('cart.empty_cart')
    }

    window.addEventListener('message', e => {
      if (e.data !== '') {
        let message = e.data

        switch (message.type) {
          case 'updatePaymentIframeHeight':
            if (message.data.height && message.data.height > 0 && this.iframeHeight !== message.data.height) {
              this.iframeHeight = message.data.height
            }
            break
          case 'paySuccess':
            this.$store.dispatch('cart/clear')
            this.isMessageVisible = true
            this.messageText = this.$t('cart.thanks_for_your_order')
            break
        }
      }
    })
  },
  methods: {
    date(date, region) {
      let currentTimeZone
      const dateISO = DateTime.fromISO(date)

      this.isInDST = isInDST(date, region)

      if (this.isInDST) {
        currentTimeZone = timeZone[region].zoneSummer
      } else {
        currentTimeZone = timeZone[region].zone
      }

      let formatted = dateISO.setZone(currentTimeZone)

      return formatted.toFormat('dd MMM yyyy, HH:mm')
    },
    addAnotherCard() {
      this.$store.dispatch('card/addAnotherCard')
      this.$store.dispatch('card/setProductId', this.product.id)
      this.$store.dispatch('card/setCardImage', this.product.images[0])
      this.$router.push({ name: 'AddCard' })
    },
    editPosition(id) {
      const index = findIndex(this.$store.getters['cart/positions'], { id: id })

      if (index >= 0) {
        this.$store.dispatch('card/setAll', Object.assign({}, this.$store.getters['cart/positions'][index]))
        this.$router.push({ name: 'AddCard' })
      }
    },
    removePosition(id) {
      this.$modal.show(ModalAccess, {
        id: id,
        store: this.$store,
        title: this.$t('modals.title_access'),
        buttonCancel: this.$t('modals.buttons.cancel'),
        buttonDelete: this.$t('modals.buttons.delete')
      })

      if (this.$store.getters['cart/quantity'] === 0) {
        this.isMessageVisible = true
        this.messageText = this.$t('cart.empty_cart')
      }
    },
    getGAClientId() {
      let cookie = {}

      document.cookie.split(';').forEach(function(el) {
        let splitCookie = el.split('=')
        let key = splitCookie[0].trim()
        let value = splitCookie[1]
        cookie[key] = value
      })

      return cookie["_ga"] ? cookie["_ga"].substring(6) : ''
    },
    showHideOverlayCheckedOffer() {
      if (this.$store.state.main.isMobile) {
        return
      }

      let mainOverlay = document.querySelector('.gc-highlight-element')
      let clone = document.querySelector('.basket-agree').cloneNode(true)
      const cloneClientRect = document.querySelector('.basket-agree').getClientRects()
      const container = document.querySelector('.gc-highlight-element__content')

      mainOverlay.classList.add('gc-highlight-element--visible')
      container.appendChild(clone)

      clone.style.position = 'relative'
      clone.style.top = cloneClientRect[0].top + 'px'
      clone.style.bottom = cloneClientRect[0].bottom + 'px'

      setTimeout(() => {
        this.hideOverlayCheckedOffer()
      }, 2000)
    },
    hideOverlayCheckedOffer() {
      let mainOverlay = document.querySelector('.gc-highlight-element')
      const container = document.querySelector('.gc-highlight-element__content')

      mainOverlay.classList.remove('gc-highlight-element--visible')
      container.replaceChildren()
    },
    pay() {
      if (!this.checkedOffer) {
        this.showHideOverlayCheckedOffer()

        return
      }

      if (this.isProcessing) return

      this.isProcessing = true
      let stat = {}
      let params = {}

      if (typeof this.$store.state.main.code !== 'undefined') {
        stat.widgetConfigCode = this.$store.state.main.code
      }

      // Giftery Data Layer for E-commerce
      window.gifteryDataLayer = {
        event: 'pay',
        data: {
          productId: this.product.id,
          productTitle: this.product.title,
          positions: this.$store.getters['cart/positions'].map(position => {
            return {
              face: position.face,
              quantity: position.quantity,
              type: position.type
            }
          }),
          sum: this.$store.getters['cart/sum']
        }
      }

      if (typeof window.roistat !== 'undefined') {
        stat.roistatVisit = window.roistat.getVisit()
      }

      if (window.yaCounter2199583) {
        window.ym(2199583, 'reachGoal', 'sert_purchase')
      }

      if (window.yaCounter33711084) {
        if (this.product.id === 17364) {
          window.ym(33711084, 'reachGoal', 'pay_success')
        }
      }

      try {
        if (this.product.id === 11501) {
          // console.log('sending litres event')
          /*eslint-disable no-undef*/
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'sert_purchase')
          }
          /*eslint-enable no-undef*/
        }
      } catch (e) {
        console.log(e)
      }

      if (this.$store.state.cart.promoCode && this.$store.state.cart.promoCode.promocode) {
        params['promocode'] = this.$store.state.cart.promoCode.promocode
      }

      if (this.orderId) {
        params['orderId'] = this.orderId
      }

      if (typeof this.$store.state.main.options.psOverride === 'string' && this.$store.state.main.options.psOverride !== '') {
        params['psOverride'] = this.$store.state.main.options.psOverride
      }

      params['demo'] = this.$store.state.main.isDemo

      params['successUrl'] = getSuccessUrl(window.location.href)
      params['failUrl'] = getFailUrl(window.location.href)

      const externalParams = queryString.parse(location.search).externalParams

      if (externalParams) {
        params['externalParams'] = externalParams
      }

      const gaClientId = this.getGAClientId()

      if (gaClientId) {
        stat['ga_client_id'] = gaClientId
      }

      if (queryString.parse(location.search).analytics) {
        const analytics = queryString.parse(location.search).analytics

        for (let key in analytics) {
          stat['analytics_' + key] = analytics[key]
        }
      }

      if (Object.values(stat).length > 0) {
        params['stat'] = stat
      }

      if (this.paymentGate === paymentGate.sbp) {
        params['psOverride'] = paymentGate.sbp

        order.make(this.$store.getters['cart/positions'], params)
          .then((response) => {
            this.$modal.show(ModalPaySBP, {
              store: this.$store,
              router: this.$router,
              gateResponse: response.data.payment.gateResponse,
              amount: response.data.payment.amount,
              paymentCode: response.data.payment.paymentCode,
              buttonCancel: this.$t('modals.buttons.cancel'),
              buttonDelete: this.$t('modals.buttons.delete')
            })

            this.isProcessing = false
          }).catch((error) => {
            console.log(error)
          })
      } else {
        order.make(this.$store.getters['cart/positions'], params)
          .then((response) => {
            this.$store.dispatch('pay/setPayment', response.data.payment)

            // this.paymentProcessingComponent = this.$store.getters['pay/payment'].ps

            if (['cloudpayments', 'cloudpayments-kz'].includes(response.data.payment.ps)) {
              let payData = {}

              if (this.$store.state.region.macroregion === 'kz') {
                let senderEmail = this.$store.getters['cart/positions'][0]['senderEmail'] === null ? this.$store.getters['cart/positions'][0]['recipientEmail'] : this.$store.getters['cart/positions'][0]['senderEmail']
                let checkItems = this.$store.getters['cart/positions'].map(position => ({
                  label: this.$store.state.main.product.title,
                  price: position.face,
                  quantity: position.quantity,
                  amount: position.face * position.quantity,
                  measurementUnit: 'шт'
                }))

                payData = {
                  CloudPayments: {
                    customerReceipt: {
                      items: checkItems,
                      email: senderEmail,
                      amounts: {
                        electronic: this.$store.getters['cart/sum']
                      }
                    }
                  }
                }
              }

              let widget = new window.cp.CloudPayments()
              widget.pay('charge',
                {
                  publicId: process.env[regionCloudPaymentsPublicId[this.$store.state.region.macroregion]], //id из личного кабинета
                  description: 'Оплата подарочных карт', //назначение
                  amount: parseInt(response.data.payment.amount), //сумма
                  currency: this.$store.state.region.currencyCode, //валюта
                  // accountId: 'user@example.com', //идентификатор плательщика (необязательно)
                  invoiceId: response.data.payment.gateResponse.orderId, //номер заказа  (необязательно)
                  skin: 'mini', //дизайн виджета (необязательно)
                  data: payData
                },
                {
                  onComplete: () => { },
                  onSuccess: () => {
                    this.$router.push({ name: 'PaySuccess' })
                  },
                  onFail: (reason) => {
                    if (reason === 'User has cancelled') {
                      this.isProcessing = false
                      this.isPayVisible = false
                      this.isOrderMade = false
                    } else {
                      this.$router.push({ name: 'PayFail' })
                    }
                  }
                }
              )
            } else {
              // console.log(response.data.payment.gateResponse)
              window.location.href = response.data.payment.gateResponse.formUrl
            }

            this.$store.dispatch('card/setAll', new Position())
            this.$store.dispatch('card/setProductId', this.product.id)
            this.$store.dispatch('card/setCardImage', this.product.images[0])

            this.isProcessing = false
            this.isOrderMade = true

            if (this.$store.state.main.isDemo) {
              this.isDemoPayVisible = true
              this.$store.dispatch('cart/clear')
              this.isMessageVisible = true
              this.messageText = this.$t(`cart.test_gift_card_is_sent.${ this.$store.state.main.product.kind }`)
            } else {
              this.isPayVisible = true
            }
          }).catch((error) => {
            this.isProcessing = false

            if (this.$store.state.main.isDemo) {
              this.$router.push({ name: 'PaySuccess' })
            } else {
              let titleModal = ''
              let textModal = ''
              if (availableErrors.includes(error.message)) {
                titleModal = this.$t(`errors.${error.message}.heading`)
                textModal = this.$t(`errors.${error.message}.text`)
              } else {
                titleModal = this.$t(`errors.default.heading`)
                textModal = this.$t(`errors.default.text`)
              }

              this.$modal.show(ModalPayFail, {
                store: this.$store,
                title: titleModal,
                text: textModal,
                buttonOk: this.$t('modals.buttons.ok')
              })
            }

            Vue.$logger.debug('Order | order.make | ошибка: ', error)
          })
      }


    },
    backToCards() {
      this.isProcessing = false
      this.isPayVisible = false
      this.addAnotherCard()
    },
    onStick($e) {
      this.btnSticked = $e.sticked
    },
    scrollToBasketFooter() {
      const basketBottomElement = document.querySelector('.basket__bottom--mobile')

      if (basketBottomElement) {
        scrollIntoView(basketBottomElement, {
          block: 'center',
          behavior: 'smooth',
          inline: 'center'
        })
      }
    },
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>


<style lang="scss" scoped>
.basket-pay {
  width: 100%;
  margin-top: rem(30);
  padding: rem(30) 0;

  &__loading-container {
    &-text {
      color: #FA6400;
      text-align: center;
    }
  }
}

.basket-empty {
  &__header {
    height: rem(50);
    background-color: #E9EBEC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(20) rem(20) 0 0;
    padding: rem(15) rem(20);

    @include mobile {
      padding: rem(10);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 120%;
    color: #0E1E0E;
    text-align: center;
  }

  &__content {
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 0 rem(20) rem(20);
    padding: rem(30) rem(40);

    @include mobile {
      flex-direction: column;
      padding: rem(30) rem(10);
    }
  }

  &__text {
    font-size: rem(13);
    line-height: 160%;
    color: #666666;
    text-align: center;
    margin-bottom: rem(30);
  }
}

.basket {
  &__content {
    padding-bottom: rem(30);

    @include mobile {
      padding-bottom: 0;
    }
  }

  &__bottom {
    padding-top: rem(20);
    border-top: rem(2) solid var(--theme-secondary-background-component);

    @include mobile {
      display: none;
    }

    &--mobile {
      display: none;

      @include mobile {
        display: block;
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: rem(160) 1fr;
    grid-gap: rem(20);
    margin-bottom: rem(20);
    padding-left: rem(20);

    @include mobile {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
    }

    &-block {
      display: flex;
      align-items: center;
      margin-bottom: rem(15);


      &:first-child {
        background-color: #ffffff;
        justify-content: space-between;
        border-radius: rem(4);
        border: rem(1) solid #F1F2F3;
        padding: rem(21) rem(16) rem(20);
        margin-bottom: rem(23);
      }

      &:nth-child(3) {
        order: 2;
        margin-top: rem(14);
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: rem(15);
      }

      &::v-deep {
        .btn {
          width: 100%;

          &--link {
            @include mobile {
              width: auto;
            }
          }

          &--sticked {
            padding-left: rem(28);
            padding-right: rem(28);

            span {
              width: 100%;
              display: flex;
              justify-content: space-between;

              &:first-child {
                justify-content: flex-start;
              }

              &:last-child {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    &-col {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
      }

      &.--block-pay {
        background-color: #ffffff;
        justify-content: space-between;
        border-radius: rem(20);
        border: rem(1) solid #F1F2F3;
        padding: rem(20) rem(20);

        .basket__footer-col {
          &:first-child {
            width: rem(120);
          }

          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }

  &__b2b::v-deep {
    background-color: #ffffff;
    font-size: rem(15);
    line-height: 140%;
    color: #0B132A;
    text-align: center;
    border: rem(1) solid #FA6400;
    border-radius: rem(4);
    padding: rem(25) rem(90) rem(12);
    margin-top: rem(50);

    @include mobile {
      background-color: #F9FAFB;
      font-size: rem(13);
      text-align: left;
      padding: rem(12) rem(16);
      margin-top: rem(30);
    }

    a {
      color: #FA6400;
    }

    p {
      margin: 0 0 rem(13) 0;

      @include mobile {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.basket-picture {
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: rem(10);
  padding: rem(30) rem(20);
  margin: auto;

  @include mobile {
    width: auto;
    margin: auto;
    padding: rem(30) rem(50);
  }

  &__image {
    width: 100%;
    max-width: rem(200);
    margin-bottom: rem(20);

    &--default-color {
      --color-main-hue: 15;
      --color-main-saturation: 87%;
      --color-main-lightness: 58%;
    }

    svg g {
      fill: var(--theme-secondary-background-component);
    }
  }

  &__text {
    font-weight: 300;
    font-size: rem(24);
    text-align: center;
    margin-bottom: rem(20);
  }

  &--no-padding {
    padding: 0;

    @include mobile {
      padding: 0;
    }
  }
}

.basket-agree {
  font-size: rem(10);
  line-height: 140%;
  color: #6E7882;
  display: grid;
  grid-template-columns: rem(160) auto;
  grid-gap: rem(20);
  padding-left: rem(20);
  margin-bottom: rem(20);

  @include mobile {
    max-width: rem(290);
    display: flex;
    grid-gap: 0;
    font-size: rem(10);
    margin-bottom: rem(0);
    padding-left: 0;
  }

  &__cell {
    display: flex;

    &:last-child {
      padding-left: rem(120);
      padding-right: rem(20);

      @include mobile {
        padding-left: 0;
      }
    }
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(10);
  }

  &__text {
    display: inline-block;
    padding-top: rem(2);
  }

  &::v-deep {
    .btn--link {
      font-size: rem(12);
      text-decoration: underline;
      text-transform: lowercase;
      color: var(--theme-primary-background-component);
    }
  }

  .widget-input-checkbox {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.basket-buttons {
  display: grid;
  grid-template-columns: rem(160) auto;
  grid-gap: rem(20);
  padding-left: rem(20);

  &__cell {
    display: flex;

    &:last-child {
      padding-left: rem(120);
    }
  }
}

.basket-sum {
  flex-grow: 1;
  line-height: 1;
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__discount {
    font-weight: 400;
    font-size: rem(13);
    line-height: 140%;
    white-space: nowrap;
    color: #6E7882;
    margin-bottom: rem(2);

    &-percent {
      font-weight: 600;
      color: #FA6400;
      margin-left: rem(5);
    }
  }

  &__total {
    font-size: rem(16);
    font-weight: 500;
    color: #0B132A;

    &-old,
    &-new {
      white-space: nowrap;
      display: inline-block;
    }

    &-old {
      font-size: rem(13);
      font-weight: 400;
      text-decoration: line-through;
      margin-right: rem(4);
    }
  }
}

.basket-btn-tip {
  flex-grow: 1;
  position: relative;
  padding: 0 rem(24);
  appearance: none;

  &__content {
    max-width: rem(190);
    min-width: rem(190);
    background: #ffffff;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0E1E0E;
    text-align: left;
    white-space: normal;
    position: absolute;
    left: 50%;
    bottom: rem(65);
    opacity: 0;
    border-radius: rem(10);
    padding: rem(10) rem(20);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #ffffff;
      transform: translate(-50%, 0);
    }
  }

  &:hover {
    .basket-btn-tip__content {
      opacity: 1;
      transform: translate(-50%, 0);

      @include mobile {
        opacity: 0;
      }
    }
  }
}

.basket-grid {
  overflow: hidden;
  border-radius: rem(4);

  @include mobile {
    display: none;
  }
}

.basket-grid-header {
  height: rem(50);
  background-color: #666666;
  font-weight: 600;
  font-size: rem(15);
  line-height: 1;
  color: #ffffff;
  display: grid;
  grid-template-columns: rem(248) 0.8fr 0.8fr 1.2fr;
  grid-gap: rem(10);
  padding: 0 rem(20);

  @include mobile {
    height: auto;
    background-color: #ffffff;
    color: #6E7882;
    display: flex;
    padding: 0 0 rem(5);
  }

  &__item {
    display: flex;
    align-items: center;

    @include mobile {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

.basket-grid-content-item {
  font-size: rem(15);
  line-height: 140%;
  overflow: hidden;
  border-radius: rem(20);
  margin-bottom: rem(30);

  &:last-child {
    margin-bottom: 0;
  }

  &__mobile {
    @include mobile {
      background-color: #ffffff;
      overflow: hidden;
      border-radius: rem(4);
      border: rem(1) solid #F1F2F3;
      padding: rem(12) rem(16);
      margin-bottom: rem(13);
    }

    &-image {
      display: none;

      @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: rem(14);
        margin-bottom: rem(20);
        filter: drop-shadow(0 rem(6) rem(16) rgba(110, 120, 130, 0.1));

        img {
          width: 100%;
        }
      }
    }
  }
}

.cart-item-sender {
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: rem(248) 0.8fr 0.8fr 1.2fr;
  grid-gap: rem(10);
  border-radius: 0 0 rem(20) rem(20);
  border: rem(1) solid #F1F2F3;
  padding: rem(15) rem(20);

  @include mobile {
    background-color: #ffffff;
    color: #0B132A;
    display: flex;
    border: none;
    padding: 0;
  }

  &--no-border-radius {
    border-radius: 0;
  }

  &__email,
  &__name {
    max-width: rem(240);
  }

  &__email {
    display: flex;
    align-items: center;

    &-text {
      max-width: rem(240);
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: help;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile {
      &:not(:first-child) {
        display: none;
      }
    }

    &-face > div > div:first-child {
      display: flex;
      align-items: center;
      .widget-tooltip {
        margin-left: rem(5);

        &__content {
          height: max-content;
          width: max-content;
          max-width: max-content;
          bottom: calc(100% + 7px);
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
          &:before {
            margin: 0;
          }

        }
        &__content-text::first-letter {
          text-transform: uppercase;
        }
      }
    }


    &-image {
      width: rem(107);
      height: rem(49);
      overflow: hidden;
      box-shadow: 0 0 rem(16) rgba(110, 120, 130, 0.1);
      border-radius: rem(4);

      img {
        width: 100%;
      }
    }

    &-discount {
      font-weight: 600;
      font-size: rem(15);
      line-height: 140%;
      color: #FA6400;
    }

    &-quantity {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &-btns {
      width: rem(30);
      display: flex;
      align-items: center;

      .btn:first-child {
        margin-bottom: rem(10);
      }
    }

    &--quantity {
      display: flex;
      flex-direction: row;
    }
  }
}

.cart-item-recipient {
  @include mobile {
    margin-bottom: rem(13);
  }

  &__header {
    height: rem(50);
    background: #E9EBEC;
    display: grid;
    grid-template-columns: rem(248) 0.8fr 0.8fr 1.2fr;
    grid-gap: rem(10);
    border: rem(1) solid #F1F2F3;
    padding: 0 rem(20);

    &-item {
      font-weight: 600;
      font-size: rem(16);
      line-height: 1;
      color: #0E1E0E;
      display: flex;
      align-items: center;
    }
  }

  &__email,
  &__name {
    max-width: rem(240);
  }

  &__email {
    display: flex;
    align-items: center;

    &-text {
      max-width: rem(240);
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: help;
    }
  }
}

.cart-item-recipient-item {
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: rem(248) 0.8fr 0.8fr 1.2fr;
  grid-gap: rem(10);
  border-radius: 0 0 rem(20) rem(20);
  border: rem(1) solid #F1F2F3;
  padding: rem(15) rem(20);

  &__block {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-time {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &-btns {
      width: rem(30);
      display: flex;
      align-items: center;

      .btn:first-child {
        margin-bottom: rem(10);
      }
    }

    &--time {
      display: flex;
      flex-direction: row;
      padding-left: rem(8);
    }
  }
}

.cart-item-recipient-tip {
  position: relative;
  appearance: none;
  margin-left: rem(6);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    max-width: rem(190);
    min-width: rem(190);
    background-color: #FFFFFF;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0E1E0E;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: rem(30);
    opacity: 0;
    border-radius: rem(6);
    padding: rem(10) rem(20);
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #FFFFFF;
      transform: translate(-50%, 0);
    }
  }

  &:hover {
    cursor: help;

    .cart-item-recipient-tip__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.basket-grid-mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.basket-grid-mobile-item {
  &:last-child {
    .basket-grid-mobile-item {
      &__separator {
        display: none;
      }
    }
  }

  &__image {
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: rem(14);
      margin-bottom: rem(20);
      filter: drop-shadow(0 rem(6) rem(16) rgba(110, 120, 130, 0.1));

      img {
        width: 100%;
      }
    }
  }

  &__sender {
    background-color: #FFFFFF;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(4);
    padding: rem(12) rem(16);
    margin-bottom: rem(13);

    &-header {
      font-size: rem(13);
      line-height: rem(16);
      color: #6E7882;
      margin-bottom: rem(5);
    }

    &-name {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
    }

    &-email {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
      overflow-x: scroll;
    }
  }

  &__recipient {
    background-color: #FFFFFF;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(4);
    padding: rem(12) rem(16);
    margin-bottom: rem(13);

    &-header {
      font-size: rem(13);
      line-height: rem(16);
      color: #6E7882;
      margin-bottom: rem(5);
    }

    &-name {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
    }

    &-email {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
      overflow-x: scroll;
    }

    &-phone {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
    }
  }

  &__time {
    background-color: #FFFFFF;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(4);
    padding: rem(12) rem(16);
    margin-bottom: rem(13);

    &-header {
      font-size: rem(13);
      line-height: rem(16);
      color: #6E7882;
      display: flex;
      align-items: center;
      margin-bottom: rem(5);
    }

    &-text {
      font-size: rem(15);
      line-height: 140%;
      color: #0B132A;
    }

    .cart-item-recipient-tip {
      display: inline-flex;
      align-items: center;
      margin-left: 0;

      &__icon {
        margin: 0 rem(6);
      }

      &__content-text {
        padding-top: rem(0.5);
      }
    }
  }

  &__control {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: rem(1) solid #F1F2F3;
    border-radius: rem(4);
    padding: rem(12) rem(16);
    margin-bottom: rem(13);

    &-face,
    &-quantity {
      width: 49%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-face {
      padding-right: rem(28);
    }

    &-quantity {
      text-align: center;
      padding-left: rem(28);

      .quantity-selector {
        align-items: center;
        justify-content: center;
      }
    }

    &-header {
      font-size: rem(13);
      line-height: rem(16);
      color: #6E7882;
      margin-bottom: rem(5);
    }

    &-content {
      @include mobile {
        font-size: rem(16);
        font-weight: 600;
      }
    }

    &-separator {
      width: rem(1);
      height: rem(40);
      background-color: #6E7882;
      opacity: 0.2;
    }
  }

  &__btns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(10);
    margin-bottom: rem(23);

    .btn {
      padding: 0;
    }
  }

  &__separator {
    width: 100%;
    height: rem(1);
    background-color: #6E7882;
    opacity: 0.2;
    margin-bottom: rem(23);
  }
}

.widget-tooltip {
  position: relative;
  appearance: none;
  margin-left: rem(1);
  margin-bottom: rem(1);

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    background: #ffffff;
    font-size: rem(13);
    font-weight: 400;
    line-height: 140%;
    color: #0B132A;
    text-align: left;
    position: absolute;
    left: 50%;
    bottom: rem(25);
    opacity: 0;
    border-radius: rem(10);
    padding: rem(14) rem(20);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));
    transform: translate(-50%, 1rem);
    transition: all .2s ease-in-out;
    pointer-events: none;

    @include mobile {
      top: rem(-30);
      right: rem(-85);
      bottom: auto;
      left: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border: .5em solid transparent;
      border-top-color: #ffffff;
      transform: translate(-50%, 0);
      margin-left: rem(1);

      @include mobile {
        top: rem(32);
        right: rem(-19);
        border-top-color: transparent;
        border-left-color: #ffffff;
      }
    }
  }

  &:hover {
    cursor: help;

    .widget-tooltip__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.gc-alert {
  background-color: #fef0eb;
  text-align: left;
  font-size: rem(16);
  line-height: 140%;
  color: #fa6400;
  border: none;
  padding: rem(18) rem(30);
  margin-top: rem(50);
}

.basket-payments {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    width: rem(24);
    height: rem(16);
    margin-right: rem(10);

    @include mobile {
      width: rem(30);
      height: rem(20);
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    svg {
      width: rem(24);
      height: rem(16);

      @include mobile {
        width: rem(30);
        height: rem(20);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.backet-pay-radio {
  color: #6E7882;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__checkmark {
    margin-right: rem(10);
    flex-shrink: 0;
  }

  &__content {
    font-size: rem(13);
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    white-space: nowrap;

    &-text {
      flex-grow: 1;
    }

    &-icons {
      margin-left: rem(14);
    }
  }

  &--checked {
    color: #0B132A;
  }
}

.basker-mobile__separator {
  width: 100%;
  height: rem(1);
  background-color: #E9EBEC;
}

.basket-payment-mobile {
  margin-top: rem(23);
  margin-bottom: rem(25);

  &__title {
    font-weight: 600;
    font-size: rem(15);
    line-height: 1;
    color: #0B132A;
    padding: 0 rem(16);
    margin-bottom: rem(23);
  }
}

.basket-payment-mobile-item {
  width: 100%;
  height: rem(50);
  background: #FFFFFF;
  color: #6E7882;
  border: rem(1) solid #E9EBEC;
  border-radius: rem(4);
  margin-bottom: rem(15);
  transition: 0.2s;

  &:last-child {
    margin: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: rem(14) rem(16) rem(15);
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: rem(15);
    line-height: 140%;

    &-icons {
      width: rem(30);
      height: rem(20);
      margin-right: rem(16);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--checked {
    color: #0B132A;
    border-color: #FA6400;
    transition: 0.2s;
  }
}

.basket-sum-mobile {
  flex-grow: 1;
  line-height: 1;
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__discount {
    font-weight: 600;
    font-size: rem(15);
    color: #0B132A;

    &-percent {
      font-weight: 600;
      color: #FA6400;
      margin-left: rem(5);
    }
  }

  &__total {
    font-size: rem(18);
    font-weight: 600;
    color: #0B132A;

    &-new {
      display: flex;
      align-items: center;

      .widget-tooltip {
        margin-left:0;
        margin-right:rem(5);

        &__content {
          width: max-content;
          max-width: max-content;
          right: calc(100% + 15px);
          top: 0;
          transform: translate(0, -25%) !important;
          border-radius: 10px;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1)) drop-shadow(10px 10px 10px rgba(110, 120, 130, 0.05)) drop-shadow(20px 10px 80px rgba(110, 120, 130, 0.1));

          &::first-letter {
            text-transform: uppercase;
          }

          &::before {
            transform: translate(0, -50%);
            top: 50%;
            left: 99%;
            border-top-color: transparent;
            border-left-color: white;
          }
        }
      }
    }


    &-old {
      font-size: rem(13);
      font-weight: 400;
      text-decoration: line-through;
      margin-right: rem(4);
    }
  }
}

.widget-input-checkbox {
  margin-top: rem(-6);
  margin-bottom: rem(14);
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  input {
    height: 0;
    width: 0;
    font-size: 0;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: -1;
  }

  label {
    font-size: rem(12);
    line-height: 140%;
    color: #6E7882;
    position: relative;
    padding-top: rem(1);
    padding-left: rem(24);
    transition: 0.3s;
    cursor: pointer;
  }

  &__icon {
    width: rem(15);
    height: rem(15);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: 0.1s;
  }

  &--active {

  }
}
</style>
