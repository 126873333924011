/* eslint-disable */

export function addStat (productId, configCode) {
  productId = parseInt(productId)

  if (typeof configCode === 'undefined') {
    configCode = ''
  }

  // Google Tag Manager
  if (productId !== 15376) {
    if (this.$gtm) {
      this.$gtm.enable(true)
    }
  }

  (function () {
    const script = document.createElement('script')
    script.innerHTML = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};' +
    'm[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})' +
    '(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");'
    document.getElementsByTagName('head')[0].prepend(script)
  })()

  ym(4303060, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
    trackHash:true,
    params: {
      productId: productId
    }
  });

  if (configCode === 'demo') {
    ym(4303060,'reachGoal','b2p_show_widget_demo')
  }

  window.yaParams = { "productId": productId };
  ym(12446476, 'params', window.yaParams || {});

  if (productId == 12409) {
    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-PFRF35');"
      document.getElementsByTagName('head')[0].prepend(script)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFRF35" ' +
        'height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      document.body.prepend(noscript)
    })()
  }

  if (productId == 19641) {
    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-W3RDHPJ');"
      document.getElementsByTagName('head')[0].prepend(script)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W3RDHPJ" ' +
        'height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      document.body.prepend(noscript)
    })()
  }

  if (configCode === 'litres-subscription' || configCode === 'litres') {
    (function(){
      let head = document.head || document.getElementsByTagName('head')[0]
      let script = document.createElement('script');
      /*let style = document.createElement('style')
      let css = '.async-hide { opacity: 0 !important}'

      head.appendChild(style);

      style.type = 'text/css';
      if (style.styleSheet){
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }*/

      script.type = 'text/javascript';
      script.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-TXZ7NQV';

      /*script.innerHTML = "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;\n" +
        "h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};\n" +
        "(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;\n" +
        "})(window,document.documentElement,'async-hide','CUSTOM-DATALAYER-NAME',4000,\n" +
        "{'OPT-TXZ7NQV':true});"*/

      head.prepend(script);
    })();
  }

  if (configCode === 'litres') {
    ym(2199583, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true,
      trackHash:true,
      ecommerce:"dataLayer"
    });

    // (function(){
    //   var s = document.createElement('script');
    //   s.type = 'text/javascript';s.async = true;
    //   s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-15543008-1';
    //   var ss = document.getElementsByTagName('script')[0];
    //   ss.parentNode.insertBefore(s, ss);
    // })();

    try {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('config', 'UA-15543008-1')
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (configCode.startsWith('sber-ru') && productId !== 15376) {
    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-TQX7VQ');"
      document.getElementsByTagName('head')[0].prepend(script)
      const noscript = document.createElement('noscript')
      noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQX7VQ" ' +
        'height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      document.body.prepend(noscript)
    })()
  }

  if (productId == 15830) {
    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
        "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
        "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        "ga('create', 'UA-38888758-1', 'auto');\n" +
        "ga('send', 'pageview');"
      document.getElementsByTagName('head')[0].prepend(script)
    })()
  }

  // TODO: Youtalk:18831
  if (productId == 18831) {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/49493728"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(49493728, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });

    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
        "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
        "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        "ga('create', 'UA-119829162-1', 'auto');\n" +
        "ga('send', 'pageview');"
      document.getElementsByTagName('head')[0].prepend(script)
    })()
  }

  // ИВ РОШЕ Казахстан
  if (productId == 16033) {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/92255093"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(92255093, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  }

  // Аскона Казахстан
  if (productId == 17100) {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/92255125"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(92255125, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  }

  // MIR VR
  if (productId == 21094) {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/68358556"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(68358556, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  }

  if (productId === 19157) {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/86971016"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(86971016, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });

    (function () {
      const script = document.createElement('script')
      script.innerHTML = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
        "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
        "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        "ga('create', 'UA-215810185-1', 'auto');\n" +
        "ga('send', 'pageview');"
      document.getElementsByTagName('head')[0].prepend(script)
    })()
  }

  // Askona TEST
  if (configCode == 'askona-test') {
    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/1383371"; style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noscript)

    ym(1383371, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  }

  /* if (productId == 16354) {
    (function () {
      const script = document.createElement('script')
      script.innerHTML = "!function(f,b,e,v,n,t,s)\n" +
        "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
        "  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
        "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
        "  n.queue=[];t=b.createElement(e);t.async=!0;\n" +
        "  t.src=v;s=b.getElementsByTagName(e)[0];\n" +
        "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
        "  'https://connect.facebook.net/en_US/fbevents.js');\n" +
        "fbq('init', '613052532766916');\n" +
        "fbq('track', 'PageView');"
      document.getElementsByTagName('head')[0].prepend(script)

      const noscript = document.createElement('noscript')
      noscript.innerHTML = '<img height="1" width="1" style="display:none" ' +
        'src="https://www.facebook.com/tr?id=613052532766916&ev=PageView&noscript=1"; /'
      document.body.prepend(noscript)
    })()
  } */

  /* if (productId === 17456) {
    const script = document.createElement('script')
    script.innerHTML = "!function(f,b,e,v,n,t,s)\n" +
      "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
      "  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
      "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
      "  n.queue=[];t=b.createElement(e);t.async=!0;\n" +
      "  t.src=v;s=b.getElementsByTagName(e)[0];\n" +
      "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
      "  'https://connect.facebook.net/en_US/fbevents.js');\n" +
      "fbq('init', '227740668518084');\n" +
      "fbq('track', 'PageView');"
    document.getElementsByTagName('head')[0].prepend(script)

    const noscript = document.createElement('noscript')
    noscript.innerHTML = '<img height="1" width="1" style="display:none" ' +
      'src="https://www.facebook.com/tr?id=227740668518084&ev=PageView&noscript=1"; /'
    document.body.prepend(noscript)
  } */
}
