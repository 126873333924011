<template>
  <div class="page pay-success">
    <div class="page__header">
      <div class="page__title">
        <span>{{ $t('pay.success.title') }}</span>
        <span class="page__header-icon">
          <IconCheck />
        </span>
      </div>
    </div>
    <div class="page__content">
      <div class="page__content-wrapper">
        {{ $t('pay.success.text_1') }}<span class="page__content-text-bold">{{ totalSum | setDiscount(faceMultiplier) |
            numberFormat(0, '', ' ')
        }}&nbsp;{{ $store.state.region.currency }}</span>{{ $t('pay.success.text_2') }}
      </div>
    </div>
    <div class="page__blocks--mobile">
      <SuccessBlocks />
    </div>
    <div class="page__blocks page__blocks--pc">
      <div class="page-block">
        <div class="page-block__icon">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" class="svg-background" fill="#F6C516" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.3335 19.1667C17.8771 19.1667 17.5002 19.5436 17.5002 20V30C17.5002 30.4564 17.8771 30.8333 18.3335 30.8333H31.6668C32.1233 30.8333 32.5002 30.4564 32.5002 30V20C32.5002 19.5436 32.1233 19.1667 31.6668 19.1667H18.3335ZM15.8335 20C15.8335 18.6231 16.9566 17.5 18.3335 17.5H31.6668C33.0437 17.5 34.1668 18.6231 34.1668 20V30C34.1668 31.3769 33.0437 32.5 31.6668 32.5H18.3335C16.9566 32.5 15.8335 31.3769 15.8335 30V20Z"
              fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.9842 19.5225C16.2481 19.1455 16.7677 19.0538 17.1448 19.3177L25.0002 24.8165L32.8557 19.3177C33.2327 19.0538 33.7523 19.1455 34.0163 19.5225C34.2802 19.8996 34.1885 20.4192 33.8115 20.6831L25.4781 26.5164C25.1912 26.7173 24.8093 26.7173 24.5223 26.5164L16.189 20.6831C15.812 20.4192 15.7203 19.8996 15.9842 19.5225Z"
              fill="white" />
          </svg>
        </div>
        <div class="page-block__text">
          <span v-html="$t('pay.success.block_1')"></span>
        </div>
      </div>
      <div class="page-block">
        <div class="page-block__icon">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" class="svg-background" fill="#F6C516" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.7735 16.9026C24.1479 16.6918 24.5703 16.5811 25 16.5811C25.4296 16.5811 25.8521 16.6918 26.2265 16.9026C26.6009 17.1134 26.9146 17.4171 27.1375 17.7845L27.1399 17.7884L34.1982 29.5718L34.205 29.5833C34.4233 29.9613 34.5388 30.3899 34.54 30.8264C34.5412 31.263 34.4281 31.6922 34.2119 32.0714C33.9958 32.4507 33.6841 32.7667 33.3078 32.9881C32.9316 33.2095 32.504 33.3285 32.0675 33.3333L32.0583 33.3334L17.9325 33.3333C17.496 33.3285 17.0683 33.2095 16.6921 32.9881C16.3159 32.7667 16.0042 32.4507 15.788 32.0714C15.5718 31.6922 15.4587 31.263 15.46 30.8264C15.4612 30.3899 15.5767 29.9613 15.795 29.5833L15.8018 29.5718L22.8601 17.7884L23.575 18.2167L22.8625 17.7845C23.0853 17.4171 23.3991 17.1134 23.7735 16.9026ZM24.2885 18.6472L17.2355 30.4215C17.1646 30.5464 17.127 30.6874 17.1266 30.8311C17.1262 30.9766 17.1639 31.1197 17.236 31.2461C17.308 31.3725 17.4119 31.4779 17.5373 31.5517C17.6617 31.6248 17.8028 31.6644 17.947 31.6667H32.053C32.1971 31.6644 32.3383 31.6248 32.4626 31.5517C32.588 31.4779 32.6919 31.3725 32.764 31.2461C32.836 31.1197 32.8737 30.9766 32.8733 30.8311C32.8729 30.6875 32.8354 30.5464 32.7644 30.4216L25.7125 18.6489C25.7121 18.6483 25.7118 18.6478 25.7115 18.6472C25.6373 18.5255 25.533 18.4248 25.4088 18.3549C25.284 18.2846 25.1432 18.2477 25 18.2477C24.8568 18.2477 24.7159 18.2846 24.5911 18.3549C24.4669 18.4248 24.3627 18.5255 24.2885 18.6472Z"
              fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24.9998 21.667C25.4601 21.667 25.8332 22.0401 25.8332 22.5003V25.8337C25.8332 26.2939 25.4601 26.667 24.9998 26.667C24.5396 26.667 24.1665 26.2939 24.1665 25.8337V22.5003C24.1665 22.0401 24.5396 21.667 24.9998 21.667Z"
              fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24.1665 29.1663C24.1665 28.7061 24.5396 28.333 24.9998 28.333H25.0082C25.4684 28.333 25.8415 28.7061 25.8415 29.1663C25.8415 29.6266 25.4684 29.9997 25.0082 29.9997H24.9998C24.5396 29.9997 24.1665 29.6266 24.1665 29.1663Z"
              fill="white" />
          </svg>
        </div>
        <div class="page-block__text">
          <span v-html="$t('pay.success.block_2')"></span>
        </div>
      </div>
      <div class="page-block">
        <div class="page-block__icon">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" class="svg-background" fill="#F6C516" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.1665 18.3337C18.9455 18.3337 18.7335 18.4215 18.5772 18.5777C18.421 18.734 18.3332 18.946 18.3332 19.167V30.4885L20.2439 28.5777C20.4002 28.4215 20.6122 28.3337 20.8332 28.3337H30.8332C31.0542 28.3337 31.2661 28.2459 31.4224 28.0896C31.5787 27.9333 31.6665 27.7213 31.6665 27.5003V19.167C31.6665 18.946 31.5787 18.734 31.4224 18.5777C31.2661 18.4215 31.0542 18.3337 30.8332 18.3337H19.1665ZM17.3987 17.3992C17.8676 16.9304 18.5035 16.667 19.1665 16.667H30.8332C31.4962 16.667 32.1321 16.9304 32.6009 17.3992C33.0698 17.8681 33.3332 18.504 33.3332 19.167V27.5003C33.3332 28.1634 33.0698 28.7993 32.6009 29.2681C32.1321 29.7369 31.4962 30.0003 30.8332 30.0003H21.1783L18.0891 33.0896C17.8508 33.3279 17.4923 33.3992 17.1809 33.2702C16.8695 33.1412 16.6665 32.8374 16.6665 32.5003V19.167C16.6665 18.504 16.9299 17.8681 17.3987 17.3992Z"
              fill="white" />
          </svg>
        </div>
        <div class="page-block__text">
          <span v-if="product.region === 'by'">У вас остались вопросы?<br> Обратитесь в поддержу Giftery<br> по номеру
            +375 (25) 518-81-74</span>
          <span v-else v-html="$t('pay.success.block_3')"></span>
        </div>
      </div>
    </div>
    <template v-if="![17019].includes(product.id)">
      <div class="page__account" v-if="product.region !== 'es' && !$store.state.main.isDemo">
        <div class="page-account">
          <div class="page-account__title">{{ $t(`pay.success.account-title.${ $store.state.main.product.kind }`) }}</div>
          <div class="page-account__btn">
            <default-btn class="widget-action--payment-success-go-to-personal-account" :href="'https://my.giftery.ru/'"
              :target="'_blank'" big>
              {{ $t('pay.success.account-btn') }}
            </default-btn>
          </div>
        </div>
      </div>

      <div class="page__account" v-if="product.region !== 'es' && $store.state.main.isDemo">
        <div class="page-account">
          <div class="page-account__title">
            {{ $t('pay.success.account-title-demo') }}
          </div>
          <div class="page-account__btn">
            <default-btn class="widget-action--payment-success-demo-submit-your-app btn__demo-submit-your-app"
              :href="product.region === 'kz' ? 'https://www.giftery.kz/forms/supplier' : 'https://partner.giftery.ru/registration'"
              :target="'_blank'" big>
              {{ $t('pay.success.account-btn-demo') }}
            </default-btn>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import IconCheck from '../components/icons/IconCheck'
import { numberFormat } from 'app/modules/utils'
import { timeZone } from 'app/modules/constants'
import { isInDST } from 'app/modules/datetime'
import { DateTime } from 'luxon'
import DefaultBtn from '../components/default/DefaultBtn'
import SuccessBlocks from '../components/sliders/SuccessBlocks'

export default {
  components: { IconCheck, DefaultBtn, SuccessBlocks },
  data() {
    return {
      totalSum: 0
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    setDiscount(face, faceMultiplier) {
      if (faceMultiplier < 1) {
        return face * faceMultiplier
      }
      return face
    }
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    faceMultiplier() {
      if (this.$store.state.cart.promoCode) {
        return 1 - this.$store.state.cart.promoCode.discount / 10000
      }
      else {
        return (this.product &&
          this.product.properties &&
          this.product.properties.face_multiplier) || 1
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (window.gtag) {
        window.gtag('event', 'widgetsuccessfulpaymentga')

        window.gtag('event', 'thxpagesuccessga');
        console.log("(gtag) Спасибо - Результат оплаты | Успешная оплата");
      }

      if (window.yaCounter12446476) {
        window.ym(12446476, 'reachGoal', 'widgetsuccessfulpayment')

        window.ym(12446476, 'reachGoal', 'thxpagesuccess');
        console.log("(ym) Спасибо - Результат оплаты | Успешная оплата");
      }

      if (window.yaCounter68358556) {
        window.ym(68358556, 'reachGoal', 'certificate-purchase');
      }

      if (window.yaCounter33711084) {
        if (this.product.id === 17364) {
          window.ym(33711084, 'reachGoal', 'cert_paid')
        }
      }

      if (this.product.id === 18831) {
        this.sendYouTalk()
      }

      if (this.product.id === 12409) {
        if (window.yaCounter7294060) {
          window.ym(7294060, 'reachGoal', 'widgetsuccessfulpayment')
        }
        if (window.yaCounter48824588) {
          window.ym(48824588, 'reachGoal', 'widgetsuccessfulpayment')
        }
      }

      // TODO: Временное решение. С этим нужно что-то делать
      if (this.$store.state.main.options.code === 'askona-test') {
        this.sendAskonaPurchase()
      } else if (this.product.id === 15873) {
        this.sendAskona()
      } else {
        this.sendYandexEcommerce()
      }

      window.history.pushState('', '', window.location.href.replace('&success=1', ''))

      this.totalSum = this.$store.getters['cart/sum']
      this.$store.dispatch('cart/clear')
    })
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    date(date, region, isDateTime) {
      let currentTimeZone
      const dateISO = DateTime.fromISO(date)

      this.isInDST = isInDST(date, region)

      if (this.isInDST) {
        currentTimeZone = timeZone[region].zoneSummer
      } else {
        currentTimeZone = timeZone[region].zone
      }

      let formatted = dateISO.setZone(currentTimeZone)

      if (isDateTime) {
        return formatted.toFormat('dd MMM yyyy, HH:mm')
      } else {
        return formatted.toFormat('yyyy-MMM dd')
      }
    },
    // 18831
    sendYouTalk() {
      const targetOrigin = 'https://youtalk.ru/sertifikat-na-psihoterapiyu/'
      const targetOriginEx = 'https://youtalk.international/sertifikat-na-psihoterapiyu/'
      const positions = this.$store.getters['cart/positions']
      const title = this.product ? this.product.title : ''

      window.dataLayer.push({
        event: 'purchaseCertificate',
        action: 'pay',
        label: 'purchaseCertificate',
        value: this.$store.getters['cart/sum']
      })

      window.top.postMessage({
        event: 'purchaseCertificateYouTalk',
        sum: this.$store.getters['cart/sum'],
        positions: positions.map(position => {
          return {
            name: title,
            price: position.face,
            quantity: position.quantity
          }
        })
      }, targetOrigin)

      window.top.postMessage({
        event: 'purchaseCertificateYouTalk',
        sum: this.$store.getters['cart/sum'],
        positions: positions.map(position => {
          return {
            name: title,
            price: position.face,
            quantity: position.quantity
          }
        })
      }, targetOriginEx)
    },
    sendAskonaPurchase() {
      try {
        window.dataLayer = window.dataLayer || [];
        const positions = this.$store.getters['cart/positions']
        const dateStr = this.date(new Date().toISOString(), this.product.region, true)
        window.dataLayer.push({
          "event": "purchase",
          "ecommerce": {
            "currencyCode": "RUB",
            "purchase": {
              "actionField": {
                "id": "new" + dateStr,
                "revenue": this.$store.getters['cart/sum'],
                "affiliation": "Askona",
                "coupon": "Оформление заказа - покупка сертификата"
              },
              "products": positions.map(position => {
                return {
                  "id": position.productId,
                  "name": "Подарочный сертификат",
                  "price": position.face,
                  "discount": 0,
                  "brand": "Askona",
                  "category": "Сертификаты",
                  "coupon": "Добавление в корзину - сертификат",
                  "variant" : position.face + " р",
                  "list": "/landing/gift_certificates/",
                  "position": 0,
                  "quantity": position.quantity
                }
              })
            }
          }
        });
        window.ym(1383371,'reachGoal','purchase_notec', {"item": "certificate", "page_path": "/landing/gift_certificates/"});
      } catch (e) {
        console.error(e)
      }
    },
    sendAskona() {
      if (window.dataLayer) {
        const positions = this.$store.getters['cart/positions']
        const title = this.product ? this.product.title : ''
        const dateStr = this.date(new Date().toISOString(), this.product.region)

        window.dataLayer.push({
          eventData: {
            pageType: 'order',
            realCountry: this.product.region,
            phone: '',
            first_name: '',
            last_name: '',
            email: '',
            date: dateStr,
            category: 'ecommerce',
            action: 'transaction',
            label: 'sertificate'
          },
          event: 'MyEvent',
          gtm: { uniqueEventId: 227 },
          order: {
            id: 'quick' + dateStr.replace(/\s/g, ''),
            revenue: positions[0].face,
          },
          ecommerce: {
            currencyCode: this.$store.state.region.currencyCode,
            purchase: {
              actionField: {
                affiliation: 'Askona',
                id: 'quick' + dateStr.replace(/\s/g, ''),
                option: 'Страница спасибо',
                revenue: ''
              },
              products: positions.map(position => {
                return {
                  id: 12166885,
                  productid: 12166885,
                  regionproductid: 12166885,
                  name: title,
                  category: 'Сертификаты',
                  price: position.face,
                  quantity: position.quantity,
                  brand: 'Askona',
                  variant: 'вариант дизайна 1'
                }
              })
            }
          },
        })
      }
    },
    sendYandexEcommerce() {
      if (window.dataLayer) {
        const positions = this.$store.getters['cart/positions']
        const title = this.product ? this.product.title : ''
        const dateStr = this.date(new Date().toISOString(), this.product.region, true)

        window.dataLayer.push({
          ecommerce: {
            currencyCode: this.$store.state.region.currencyCode,
            purchase: {
              actionField: {
                id: dateStr
              },
              products: positions.map(position => {
                return {
                  id: position.productId,
                  name: title,
                  category: 'Виджет',
                  price: position.face,
                  quantity: position.quantity,
                  variant: position.type === 'friend' ? 'Отправить в подарок' : 'Отправить себе'
                }
              })
            }
          },
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.page {
  &__header {
    height: rem(50);
    background-color: #E9EBEC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(20) rem(20) 0 0;
    padding: rem(15) rem(20);

    @include mobile {
      padding: rem(10);
    }

    &-icon {
      width: rem(18);
      height: rem(18);
      display: inline-flex;
      position: relative;
      margin-left: rem(10);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 120%;
    color: #0E1E0E;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: rem(15);
    }
  }

  &__content {
    background: #FFFFFF;
    font-size: rem(13);
    line-height: 160%;
    color: #666666;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 rem(20) rem(20);
    padding: rem(30);
    margin-bottom: rem(20);

    @include mobile {
      font-size: rem(12);
      flex-direction: column;
      padding: rem(30) rem(10);
    }

    &-text-bold {
      font-weight: 600;
      color: #0B132A;
      margin: 0 rem(4);
      text-decoration: underline;
    }
  }

  &__blocks {
    display: grid;
    grid-gap: rem(20);
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: rem(20);

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: rem(13);
    }

    &--pc {
      display: grid;

      @include mobile {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include mobile {
        display: block;
      }
    }
  }

  &__account {
    margin-top: rem(20);
  }

  &__btns::v-deep {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      margin-right: rem(20);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.page-block {
  background: #FFFFFF;
  border-radius: rem(20);
  padding: rem(30) rem(10);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(20);
  }

  &__text {
    font-size: rem(13);
    line-height: 160%;
    text-align: center;
    color: #666666;
    @include mobile {
      font-size: rem(12);
      line-height: 160%;
      color: #6E7882;
    }

    &::v-deep a {
      font-weight: 600;
      line-height: 1;
      color: #0E1E0E;

      &:hover {
        color: #0E1E0E;
      }
    }
  }
}

.page-account {
  background: #FFFFFF;
  border-radius: rem(20);
  padding: rem(40) rem(20);

  &__title {
    font-weight: 600;
    font-size: rem(16);
    line-height: 120%;
    text-align: center;
    color: #0B132A;
    margin-bottom: rem(20);
    @include mobile {
      font-size: rem(15);
      margin-bottom: rem(30);
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.svg-background {
  fill: var(--theme-secondary-background-component);
}
</style>
